
export const URLS = {
  sendOtp: "login/send/otp",
  verifyOtp: "login/verify/otp",
  getCities: "utility/getCities",
  tripForcast: "trip/forcast",
  getVehicleDetails: "vehicle/get/vehicle",
  getVehicleChallan: "vehicle/get/challan",
  getFastagTxn: "vehicle/get/fastag",
  sendOtpFastag: "vehicle/get/idfc/vehicles/otp",
  verifyOtpFastag: "vehicle/verify/idfc/vehicles/otp",
  getAllVehicle: "vehicle/get/vehicles",
  searchDriver: "searchDriver/drivers",
  searchDriverHistory: "searchDriver/history",
  STORE_MEDIA_FILE: "media/upload",
  driverUpdateDetails: "login/update/details",
  driverSendInterest: "searchDriver/send/interest",
  driverGetJobs: "driver/search/jobs",
  driverBidJobs: "driver/bid/job",
  GET_SIGNED_URL: "media/signedUrlWithKey",
  
  tripSendOtp : "driver/tripVerify/send/otp",
  tripVerifyOtp : "driver/tripVerify/verify/otp",
  tripStart : "driver/trip/start",
  tripEnd:"driver/trip/end",
  createOrder :"searchDriver/payment/order/create",
  tripPaymentHistory :"searchDriver/trip/payments"

};
