import React, { useEffect, useRef, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../store/actions/LoginAction";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../Utils/functions";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../Core/Icons";
import { DatePickerField } from "../../Core/DatePicker/input";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { storeMedia } from "../../store/actions/appActions";
import DriverHeader from "../../SubComponents/DriverHeader";
import BidModal from "../../SubComponents/BidModal";
import StartTripModal from "../../SubComponents/StartTripModal";
import { driverGetJobs, tripSendOtp } from "../../store/actions/driverAction";
import moment from "moment";
import { motion } from "framer-motion";
import { Autocomplete, TextField } from "@mui/material";

//toast.configure()
function DriverDashboard(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");
  const dispatch = useDispatch();

  const [reload, setReload] = useState();

  const [selectedHero, setSelectedHero] = useState(1);

  const [openBidModal, setOpenBidModal] = useState(false);
  const [startTripModal, setStartTripModal] = useState(false);
  const [jobData, setJobData] = useState(null);

  let driver = JSON.parse(sessionStorage.getItem("_driver"));
  console.log("oejroj", driver);

  const [locationFetch, setLocationFetch] = useState(null);

  const { driverInterestedJobs, driverNotInterestedJobs, driverJobsLoader } =
    useSelector((state) => state?.driver);
  const [loader1, setLoader1] = useState(false);
  useEffect(() => {
    if(locationFetch && locationFetch?.latitude && locationFetch?.longitude){
      dispatch(
        driverGetJobs(
          {
            jobType: "Home",
            latitude: locationFetch?.latitude,
            longitude: locationFetch?.longitude,
          },
          (resp) => {
            setLoader1(false);
          }
        )
      );
    }
    else{
      setLoader1(true);
      if ("geolocation" in navigator) {
        navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log(result.state);
            navigator.geolocation.getCurrentPosition(function (position) {
              console.log("msoejfojwe", position);
              dispatch(
                driverGetJobs(
                  {
                    jobType: "Home",
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  },
                  (resp) => {
                    setLoader1(false);
                  }
                )
              );
            });
          } else if (result.state === "prompt") {
            // navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            console.log("jeirjoge",driver?.locations)
            let lat =null,long=null;
            if(driver?.locations?.length>0){
              for (let index = 0; index < driver?.locations?.length; index++) {
                const element = driver?.locations?.[index];
                if(element?.latitude && element?.longitude){
                  lat = element?.latitude;
                  long = element?.longitude;
                  break;
                }
              }
            }
            if(driver?.locations?.length>0 && lat && long){
              dispatch(
                driverGetJobs(
                  {
                    jobType: "Home",
                    latitude: lat,
                    longitude: long,
                  },
                  (resp) => {
                    setLoader1(false);
                  }
                )
              );
            } 
            else{
              dispatch(
                driverGetJobs(
                  {
                    jobType: "Home",
                    latitude: "12.9143044",
                    longitude: "77.6324366",
                  },
                  (resp) => {
                    setLoader1(false);
                  }
                )
              );
            }
          }})

      } 
    }
  }, [reload,locationFetch]);

  // useEffect(() => {
  //   let timeout = setTimeout(
  //     () =>
  //       setSelectedHero((prevIndex) =>
  //         prevIndex+1 == driverInterestedJobs?.length+1 ? 1 : prevIndex + 1,
  //       ),
  //     5000,
  //   );

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [selectedHero]);

  const fadeInRightVariants = {
    hidden: { opacity: 0, x: 100 }, // Starts off the screen (right)
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }, // Fades in and moves to the center
    exit: { opacity: 0, x: -100, transition: { duration: 0.8 } }, // Fades out and moves to the left
  };

  return (
    <div className="">
      <DriverHeader />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 0 0", gap: "10px", padding: "0 7% 0 7%" }}
      >
        {driver?.locations?.length > 0 ? (
          <div className="d-flex">
            <div
              style={{
                width: "50%",
              }}
            >
              <Autocomplete
                // freeSolo
                options={driver?.locations}
                loading={loader}
                isOptionEqualToValue={(option, value) => {
                  return option?.cityName == value?.cityName;
                }}
                getOptionLabel={(item) => {
                  console.log("Tripmkmeromore", item);
                  let reqValue = "";
                  if (item) {
                    reqValue = item?.cityName;
                  }
                  return reqValue;
                }}
                value={locationFetch || ""}
                onInputChange={(event, newValue) => {
                  console.log("Tripjiejroj", event, newValue);

                  if (!newValue) {
                    // setUserId("");
                    setLocationFetch(null);
                    // setFieldValue("locations", null, true);
                  }
                }}
                onChange={(event, newValue) => {
                  setLocationFetch(newValue);
                  // setFieldValue("locations", newValue, true);
                  // dispatch(
                  //   driverGetJobs(
                  //     {
                  //       jobType: "Home",
                  //       latitude: newValue?.latitude,
                  //       longitude: newValue?.longitude,
                  //     },
                  //     () => {}
                  //   )
                  // );
                }}
                sx={{
                  // width: '300px',          // Custom width
                  // backgroundColor: '#f0f0f0', // Background color for the input
                  borderRadius: "8px", // Rounded corners
                  "& .MuiInputBase-root": {
                    padding: "0px",
                    margin: 0, // Custom padding inside the input
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "blue", // Custom color for dropdown arrow
                  },
                  "& .MuiAutocomplete-listbox": {
                    backgroundColor: "#fff", // Background color for dropdown options
                  },
                }}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    {/* <input
                type="text"
                {...params.inputProps}
                style={{ width: "100%" }}
                placeholder="From"
                // value={value}
                // onChange={(e) => setFieldValue("from", e.target.value, true)}
                className="inputBoxAddCust"
              /> */}

                    <TextField
                      {...params}
                      variant="standard"
                      // label="Multiple values"
                      placeholder="Current Location"
                    />
                  </div>
                )}
              />
            </div>
            <div
              onClick={() => {
                navigate("/DriverUpdateDetails");
              }}
            >
              <SvgImage name="settingsLogo" />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div style={{ textAlign: "left" }} className="fontNB24">
            Namaskar, {driver?.name || "N/A"}
          </div>
          <div style={{ textAlign: "left" }} className="fontNB24">
            ₹ {driver?.walletBalance}
          </div>
        </div>

        <div
          className="grey-box-mini d-flex"
          style={{
            justifyContent: "space-between",
            background:
              "linear-gradient(91.33deg, rgba(0, 0, 0, 0.2) 1.24%, rgba(212, 213, 214, 0.2) 60.37%)",
          }}
        >
          <div
            className="fontNB20"
            style={{ display: "flex", alignItems: "center" }}
          >
            Earnings Till Now
          </div>
          <div className="d-flex flex-column">
            <div className="fontNB18">₹ {driver?.totalEarnings}</div>
            <div
              className="fontNM12"
              style={{
                textDecoration: "underline",
                color: "#000000",
                cursor: "pointer",
              }}
              onClick={() => navigate("/driverEarnings")}
            >
              Check Details {">"}
            </div>
          </div>
        </div>

        <div
          className="d-flex mt-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="grey-box-driver-sub-mini d-flex flex-column grey-box-driver-sub-mini-interior">
            {driver?.aadhar ? (
              <>
                <div className="fontNM12">Adhaar Verified</div>
                <div
                  className="fontNR10 d-flex"
                  style={{ gap: "10px", justifyContent: "center" }}
                >
                  <SvgImage name="tick" />
                  <div>{driver?.aadhar}</div>
                </div>
              </>
            ) : (
              <>
                <div className="fontNM12">Aadhaar Not Verified</div>
                <div
                  className="fontNR10"
                  style={{
                    textDecoration: "underline",
                    color: "#000000",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/DriverUpdateDetails")}
                >
                  Verify to Get Jobs
                </div>
              </>
            )}
          </div>
          <div className="grey-box-driver-sub-mini d-flex flex-column grey-box-driver-sub-mini-interior">
            {driver?.psvBadges ? (
              <>
                <div className="fontNM12">DL Verified</div>
                <div
                  className="fontNR10 d-flex"
                  style={{ gap: "10px", justifyContent: "center" }}
                >
                  <SvgImage name="tick" />
                  <div>MH01 8393884783003</div>
                </div>
              </>
            ) : (
              <>
                <div className="fontNM12">Driver License Not Verified</div>
                <div
                  className="fontNR10"
                  style={{
                    textDecoration: "underline",
                    color: "#000000",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/DriverUpdateDetails")}
                >
                  Verify to Get Jobs
                </div>
              </>
            )}
          </div>
        </div>

        {driverJobsLoader || loader1 ? (
          <div style={{ margin: "50px 0 0 0" }}>
            <SvgImage name="AppIconLoading" />
          </div>
        ) : (
          <>
            {" "}
            {driverInterestedJobs?.length > 0 ? (
              <>
                <div style={{ textAlign: "left" }} className="fontNB24 mt-2">
                  Upcoming/Current Job
                </div>

                {driverInterestedJobs?.map((item, index) => {
                  return (
                    <>
                      {index + 1 == selectedHero ? (
                        <motion.div
                          className="grey-box-mini d-flex"
                          variants={fadeInRightVariants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          style={{
                            justifyContent: "space-between",
                            background:
                              "linear-gradient(91.33deg, rgba(0, 0, 0, 0.2) 1.24%, rgba(212, 213, 214, 0.2) 60.37%)",
                          }}
                        >
                          <div
                            className="d-flex flex-column"
                            style={{ alignItems: "flex-start" }}
                          >
                            <div className="fontNB20">
                              {" "}
                              {item?.from} - {item?.to}
                            </div>
                            <div className="fontNM12">
                              {" "}
                              {item?.date} |{" "}
                              {item?.days ? item?.days + " Days" : ""}{" "}
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="fontNB18">₹ {item?.bidAmount}</div>
                            {item?.driverStatus == 2 ? (
                              <div
                                className="addCustcenter"
                                style={{ margin: "30px 0 0 0" }}
                                onClick={() => {
                                  navigate(`/verifyTrip/${item?.searchId}`);
                                }}
                              >
                                <button
                                  className="greenButton Medium text3 AddCustStartBtn"
                                  style={{ padding: "0 20px 0 20px" }}
                                >
                                  <div className="AddCust">Start Trip</div>
                                </button>
                              </div>
                            ) : item?.driverStatus == 3 ? (
                              <div
                                className="addCustcenter"
                                style={{ margin: "30px 0 0 0" }}
                                onClick={() => {}}
                              >
                                <button
                                  className="greenButton Medium text3 AddCustStartBtn"
                                  style={{ padding: "0 20px 0 20px" }}
                                >
                                  <div className="AddCust">End Trip</div>
                                </button>
                              </div>
                            ) : item?.driverStatus == 4 ? (
                              <div
                                className="addCustcenter"
                                style={{ margin: "30px 0 0 0" }}
                                onClick={() => {}}
                              >
                                <button
                                  className="greenButton Medium text3 AddCustStartBtn"
                                  style={{ padding: "0 20px 0 20px" }}
                                >
                                  <div className="AddCust">Trip End</div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="addCustcenter"
                                style={{ margin: "30px 0 0 0" }}
                                onClick={() => {
                                  setJobData(item);
                                  dispatch(
                                    tripSendOtp(
                                      {
                                        searchId: item?.searchId,
                                      },
                                      (resp) => {
                                        console.log("kerodijojreo", resp);
                                      }
                                    )
                                  );
                                  setStartTripModal(true);
                                }}
                              >
                                <button
                                  className="greenButton Medium text3 AddCustStartBtn"
                                  style={{ padding: "0 20px 0 20px",height : "100%" }}
                                >
                                  <div className="AddCust" >Accept Job</div>
                                </button>
                              </div>
                            )}
                          </div>
                        </motion.div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <ul className="slick-dots" role="tablist">
              {driverInterestedJobs?.map((section, indx) => (
                <li
                  key={`${selectedHero}-${indx + 1}`}
                  className={selectedHero === indx + 1 ? "slick-active" : ""}
                  onClick={() => setSelectedHero(indx + 1)}
                >
                  <button type="button" role="button">
                    {indx + 1}
                  </button>
                </li>
              ))}
            </ul>
            <div style={{ textAlign: "left" }} className="fontNB24 mt-2">
              Jobs For You
            </div>
            {driverNotInterestedJobs?.map((item) => {
              let dif = moment.duration(
                moment(new Date()).diff(
                  moment(item?.createdAt, "YYYY-MM-DD HH:mm:ss")
                )
              );
              let posted1, posted2;

              if (dif?.days())
                posted1 = moment(item?.createdAt).format("DD-MM-YYYY");
              else
                posted2 =
                  (dif?.hours() ? dif?.hours() + "h " : "") +
                  (dif?.minutes()
                    ? dif?.seconds()
                      ? parseFloat(dif?.minutes()) + 1 + "m "
                      : dif?.minutes() + "m "
                    : "");

              return (
                <div
                  className="grey-box-mini-2 d-flex"
                  style={{
                    justifyContent: "space-between",
                    background: "#DEDFD733",
                  }}
                >
                  <div className="d-flex flex-column">
                    <div
                      className="d-flex flex-column"
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className="fontNB20">
                        {item?.from} - {item?.to}
                      </div>
                      <div className="fontNM12">
                        {item?.date} | {item?.days ? item?.days + " Days" : ""}{" "}
                      </div>
                    </div>
                    {item?.driverBid && (
                      <div>Current Bid : ₹ {item?.driverBid}</div>
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <div className="fontNR10" style={{ textAlign: "end" }}>
                      Posted {posted1 ? "on " + posted1 : posted2 + " ago"}
                    </div>
                    <div
                      className="addCustcenter"
                      style={{ margin: "20px 0 0 0" }}
                      onClick={() => {
                        setJobData(item);
                        setOpenBidModal(true);
                      }}
                    >
                      <button
                        className="greenButton Medium text3 AddCustStartBtn"
                        style={{ padding: "0 20px 0 20px" }}
                      >
                        <div className="fontNM12" style={{ color: "white" }}>
                          Give your Rate
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}

        <div
          className="addCustcenter"
          style={{ margin: "30px 0 50px 0" }}
          onClick={() => {
            navigate("/SearchJobs")
          }}
        >
          <button
            className="greenButton Medium text3 AddCustStartBtn"
            style={{ padding: "0 20px 0 20px" }}
          >
            <div className="AddCust">Search Job</div>
          </button>
        </div>
      </div>

      {openBidModal ? (
        <BidModal
          toggle={() => setOpenBidModal(!openBidModal)}
          modal={true}
          setModal={setOpenBidModal}
          jobData={jobData}
          setReload={setReload}
          reload={reload}
        />
      ) : (
        <></>
      )}

      {startTripModal ? (
        <StartTripModal
          toggle={() => setStartTripModal(!startTripModal)}
          modal={startTripModal}
          setModal={setStartTripModal}
          jobData={jobData}
          setReload={setReload}
          reload={reload}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default DriverDashboard;
