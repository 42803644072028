import React, { useEffect, useState } from 'react';
import socketio from "socket.io-client";

const socketcon = async () => {
    console.log("msrofre")
    let token2 = sessionStorage.getItem("_token");
    token2 = JSON.parse(token2);
    if(token2){
        return socketio("https://gke-socket.logipe.com/", {
            query: { token: token2 },
            transports: ["websocket"],
        }); 
        
    }
};
export const socket = socketcon();
export const SocketContext = React.createContext();