import React, { useEffect, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch } from "react-redux";
import { handleLoginSuccess, sendOtp, verifyOtp } from "../../store/actions/LoginAction";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../Utils/functions";
import { ClipLoader } from "react-spinners";
import { verifyOtpFastag } from "../../store/actions/TripsAction";

//toast.configure()
function CheckMoreVehicles(props) {
  const { state } = useLocation();
  const {vrn,
    requestId,msg} = state
  console.log("jojoejw",state)
  const navigate = useNavigate()
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");

  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState("");

  const handleSendOtp = () => {
    setLoader(true)
    dispatch(sendOtp({phone},(res) => {
      console.log("nanrennnn",res);
      setLoader(false)
      if(res?.status == 200){
        isSetSendOtp("1");
        // toast.success("OTP Send Successfully")
      }
    }))
  }

  useEffect(() => {
    console.log("nanrennnn",requestId,vrn);
    if(!requestId || !vrn){
        navigate("/truckRecord")
    }
  },[])

  const handleVerifyOtp = () => {
    setLoader(true)
    dispatch(verifyOtpFastag({requestId,vrn,otp},(res) => {
      console.log("nanrennnn",res);
      setLoader(false)
      if(res?.status == 200 && res?.data?.data){
        navigate("/allFastagVehicles")
      }
      else{
        //TODO: error
      }
    }))
  }


  return (
    <div className="">
      <Header />
      {(
        <div
          className="d-flex flex-column"
          style={{ margin: "50px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">Fetch All Vehicles </div>
          <div className="">
            <div className="col-12">
              <label for="vrn" className="heading3 semiBold">
                Enter OTP
              </label>
            </div>
            <div className="col-12" style={{ padding: "8px" }}>
              <PinInput
                name="pin"
                length={6}
                initialValue=""
                secret
                onChange={(value, index) =>
                  setOtp(value)
                }
                type="numeric"
                inputMode="number"
                style={
                  {
                    //   padding: "10px",
                    //   marginLeft: "96px",
                    //   marginTop: "28px",
                  }
                }
                inputStyle={{
                  border: "1px solid #A7A7A7",
                  borderRadius: "8px",
                  marginRight: "5px",
                  width: "35px",
                  height: "35px",
                }}
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <div
                style={{
                  margin: "7px 0 0px 5px",
                  color: "red",
                }}
              ></div>
            </div>
            <div>
            {msg || "OTP sent"}
              </div>
          </div>
          <div className="addCustcenter" style={{ margin: "30px 0 0 0" }} onClick={() => handleVerifyOtp()}>
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={toggleCreateCust}
            >
              <div className="AddCust">{loader ?                        <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Verify OTP"}</div>
            </button>
          </div>
        </div>
      )}
     {/* <ToastContainer position="bottom-right" /> */}
    </div>
  );
}
export default CheckMoreVehicles;
