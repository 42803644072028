import React from "react";
import SvgImage from "../../Core/Icons";
import { useNavigate } from "react-router-dom";

function BottomBox(props) {
  const {icon1,
    icon2,vrn,icon3 } = props;
  const navigate = useNavigate();

  return ( 
    <div style={{ margin: "20px 0 0 0" }}>
      {vrn && (
        <button
          className="purpleButton-2"
          onClick={() =>
            navigate("/OtherTruckRecordOptions", { state: { vrn } })
          }
        >
          Check Another Vehicle
        </button>
      )}

      <div
        className="d-flex"
        style={{
          justifyContent: "space-around",
          margin: "25px 0 50px 0",
        }}
      >
        {icon1 == "truckRecord" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/truckRecord");
            }}
          >
            <div>
              <SvgImage name="truckRecord" />
            </div>
            <div>Track Vehicle Records</div>
          </div>
        ) : icon1 == "expenseCal" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/ExpenseCalculator");
            }}
          >
            <div>
              <SvgImage name="accounting1" />
            </div>
            <div>Calculate Trip Expense</div>
          </div>
        ) : icon1 == "searchDriver" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/SearchDrivers");
            }}
          >
            <div>
              <SvgImage name="driverIcon" />
            </div>
            <div>Get Experienced Drivers</div>
          </div>
        ) : (
          <></>
        )}
        {icon2 == "truckRecord" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/truckRecord");
            }}
          >
            <div>
              <SvgImage name="truckRecord" />
            </div>
            <div>Track Vehicle Records</div>
          </div>
        ) : icon2 == "expenseCal" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/ExpenseCalculator");
            }}
          >
            <div>
              <SvgImage name="accounting1" />
            </div>
            <div>Calculate Trip Expense</div>
          </div>
        ) : icon2 == "searchDriver" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/SearchDrivers");
            }}
          >
            <div>
              <SvgImage name="driverIcon" />
            </div>
            <div>Get Experienced Drivers</div>
          </div>
        ) : icon2 == "searchDriverList" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/driverSearchHistory");
            }}
          >
            <div>
              <SvgImage name="driverIcon" />
            </div>
            <div>Get Search Drivers List</div>
          </div>
        ) : (
          <></>
        )}

{icon3 == "truckRecord" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/AllTruckRecord");
            }}
          >
            <div>
              <SvgImage name="truckRecord" />
            </div>
            <div>Track Vehicle Records</div>
          </div>
        ) : icon3 == "expenseCal" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/ExpenseCalculator");
            }}
          >
            <div>
              <SvgImage name="accounting1" />
            </div>
            <div>Calculate Trip Expense</div>
          </div>
        ) : icon3 == "searchDriver" ? (
          <div
            className="sub-link-btn d-flex flex-column"
            onClick={() => {
              navigate("/SearchDrivers");
            }}
          >
            <div>
              <SvgImage name="driverIcon" />
            </div>
            <div>Get Experienced Drivers</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <button className="purpleButton-1">
        Stay on top of your Vehicles. Download App
      </button>
    </div>
  );
}

export default BottomBox;
