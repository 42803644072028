import { applyMiddleware,createStore} from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers';
import rootSaga from './saga';
const bindMiddleware = middleware => {
    console.log("abbbbb");
    return applyMiddleware(...middleware);
};


// const middleware = [bindMiddleware([sagaMiddleware])];

function initStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer,
        initialState,
        bindMiddleware([sagaMiddleware]),
    );
    store.sagaTask = sagaMiddleware.run(rootSaga);
    return store;
}
export default initStore();