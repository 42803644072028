import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVehicle,
  getVehicleDetails,
} from "../../../../store/actions/TripsAction";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import SvgImage from "../../../../Core/Icons";
import { SocketContext } from "../../../../Utils/socket";
import DriverHeader from "../../../../SubComponents/DriverHeader";

//toast.configure()
function AllTruckRecord(props) {
  const [vrn, setVrn] = useState("");
  const { state } = useLocation();

  const [details, setDetails] = useState(state?.vrn || false);
  const dispatch = useDispatch();

  const { vehicleDetails, loader, allFastagVehcile } = useSelector(
    (state) => state?.trip
  );
  console.log("sejfoijer", allFastagVehcile);
  function GetDetails() {
    navigate(`/truckRecord/${vrn?.toUpperCase()?.replace(/ /g,'')}`)
    // dispatch(
    //   getVehicleDetails({ vrn }, (resp) => {
    //     console.log("smekfjoer", resp);
    //     if (resp?.status == 200) {
    //       setDetails(true);
    //     }
    //   })
    // ); 
  }
  const navigate = useNavigate();
  const socketConnect = useContext(SocketContext);
  const socketFunction = async () => {
    let socket = await Promise.resolve(socketConnect)
    // socket.on("connect",() => {
    //   console.log("sjifjoer")
    // })
  }
  useEffect(() => {
    socketFunction()
  },[socketConnect]) 

  useEffect(() => {
    dispatch(getAllVehicle({}, (resp) => {}));
  }, []);


  const onChange = (event) => {
    const value = event.target.value;

    if ([2, 5, 8].includes(value.length)) {
      setVrn((prev) => {
        // this is only the case when we try to delete empty space
        if (prev.endsWith(" ")) {
          return value?.toUpperCase()?.slice(0, -1);
        } else {
          return value?.toUpperCase() + " ";
        }
      });
    } else {
      setVrn(value?.toUpperCase());
    }
  };
  return (
    <div className="">
      <div className="TruckRecord">
        <DriverHeader />
        <div
          className="d-flex flex-column"
          style={{ margin: "50px 0 0 0", gap: "10px" }}
        >
          {/* <div className="FontNB24">Track Truck Record</div> */}
          <div className="FontNB24">Search with a truck number</div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            {/* <input
                type="text"
                className="inputBoxAddCust"
                onChange={(e) => setVrn(e.target.value)}
              /> */}

            <SvgImage name="numberPlate1" />
            <div className="truck-input-field-box" style={{ width: "38%" }}>
              <input
                type="text"
                style={{ width: "100%" }}
                value={vrn}
                className="truck-input-field"
                onChange={onChange}
                placeholder="AB 12 CD 3456"
              />
            </div>
          </div>
          <div
            className="addCustcenter"
            style={{ margin: "30px 0 0 0" }}
            onClick={() => {
              if (!loader && vrn) GetDetails();
            }}
          >
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={() => setSendOtp(1)}
            >
              <div className="AddCust">
                {loader ? (
                  <ClipLoader
                    color={"#2E7C7B"}
                    loading={true}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Proceed ->"
                )}
              </div>
            </button>
          </div>
        </div>

        <div className="txnDetalis" style={{ marginTop: "30px" }}>
          {allFastagVehcile?.length < 1 ? (
            <></>
          ) : (
            allFastagVehcile?.map((item, idx) => {
              let isData =
                item?.permitUpto || item?.roadTaxUpto || item?.insuranceUpto;
              return (
                <div
                  className=""
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/truckRecord/${item?.vrn?.toUpperCase()?.replace(/ /g,'')}`, {
                      state: { vrn: item?.vrn },
                    });
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "10px 0px 10px 10px",
                    }}
                  >
                    <div
                      className="fontNM18 d-flex flex-column"
                      style={{ gap: "6px" }}
                    >
                      <div style={{ textAlign: "left" }}>{item?.vrn}</div>

                      {!isData ? (
                        <div
                          className="d-flex options-truck-record"
                          style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                        >
                          <div>
                            <SvgImage name="alert" />
                          </div>
                          <div
                            className="fontNM12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Records Not Fetched
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex" style={{ gap: "5px" }}>
                          {item?.insuranceUpto && (
                            <div
                              className="d-flex options-truck-record"
                              style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                            >
                              <div>
                                <SvgImage name="tick" />
                              </div>
                              <div
                                className="fontNM12"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Insurance
                              </div>
                            </div>
                          )}
                          {item?.permitUpto && (
                            <div
                              className="d-flex options-truck-record"
                              style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                            >
                              <div>
                                <SvgImage name="tick" />
                              </div>
                              <div
                                className="fontNM12"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Permit
                              </div>
                            </div>
                          )}

                          {item?.roadTaxUpto && (
                            <div
                              className="d-flex options-truck-record"
                              style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                            >
                              <div>
                                <SvgImage name="tick" />
                              </div>
                              <div
                                className="fontNM12"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Road Tax
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {!isData && (
                      <div className="addCustcenter" style={{ margin: "10px" }}>
                        <button
                          className="greenButtonGlobal Medium text3 AddCustStartBtn"
                          style={{ padding: "0 20px 0 20px" }}
                          // onClick={() => setSendOtp(1)}
                        >
                          <div
                            className="AddCust"
                            onClick={() => {
                              console.log("smkmrkfkr");
                              navigate(`/truckRecord/${item?.vrn?.toUpperCase()?.replace(/ /g,'')}`, {
                                state: { vrn: item?.vrn },
                              });
                            }}
                          >
                            {"Get Records"}
                          </div>
                        </button>
                      </div>
                    )}

                    {/* <div className="fastagBoxDesc">{item?.challan_date_time} || {item?.challan_place}</div> */}
                  </div>
                </div>
              );
            })
          )}
        </div>

        {/* <BottomBox icon1 = {"expenseCal"} icon2 = {"searchDriver"}/> */}
      </div>
    </div>
  );
}
export default AllTruckRecord;
