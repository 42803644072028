import React, { memo, useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import SendOtp from "./Components/SendOtp";
import "./MainApp.css";
import ProctedRout from "./protectedHoc";
import ProtectedDriverHoc from "./protectedDriverHoc";
import TruckRecord from "./Components/TruckRecord";
import HomePage from "./Components/HomePage";
import CheckFASTagTrnxs from "./Components/CheckFASTagTrnxs";
import PendingChallans from "./Components/PendingChallans";
import ExpenseCalculator from "./Components/ExpenseCalculator";
import OtherTruckRecordOptions from "./Components/OtherTruckRecordOptions";
import CheckMoreVehicles from "./Components/CheckMoreVehicles";
import AllFastagVehicles from "./Components/AllFastagVehicles";
import TruckRecordVehicle from "./Components/TruckRecordVehicle";
import SearchDrivers from "./Components/SearchDriver";
import DriverSearchList from "./Components/SearchList";
import SearchHistoryList from "./Components/SearchHistoryList";
import MetaPixel from "./SubComponents/Facebook";
import DriverLogin from "./Components/DriverLogin";
import DriverRegister from "./Components/DriverRegister";
import DriverDashboard from "./Components/DriverDashboard";
import VerifyTrip from "./Components/DriverDashboard/SubComponents/VerifyTrip";
import SearchJobs from "./Components/DriverDashboard/SubComponents/SearchJobs";
import DriverEarnings from "./Components/DriverDashboard/SubComponents/DriverEarnings";
import DriverUpdateDetails from "./Components/DriverDashboard/SubComponents/DriverUpdateDetails";
import SearchList from "./Components/SearchList";
import PayDriver from "./Components/SearchDriver/SubComponents/PayDriver";
import PaymentMethods from "./Components/SearchDriver/SubComponents/PaymentMethods";
import { io } from "socket.io-client";
import { SocketContext } from "./Utils/socket";
import PaymentSuccessScreen from "./SubComponents/PaymentSuccessScreen";
import AllTruckRecord from "./Components/TruckRecord/SubComponents/AllRecors";
import OwnerDashboard from "./Components/OwnerDashboard";

//toast.configure()
function MainApp(props) {
  const { location, deviceToken, isTokenFound } = props;

  let user = sessionStorage.getItem("_user");
  console.log("ijsdrijr", user);
  const [openBidModal, setOpenBidModal] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("msoejfojwe", position);
        // setPosition({
        //   latitude: position.coords.latitude,
        //   longitude: position.coords.longitude,
        // });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const socket = useContext(SocketContext);
  // console.log("smrkfier",socket)
  // socket.on("connect", (data) => {
  //   console.log("connect");
  // });

 
  return (
    <div
      className="mobileView"
      // style={{ overflowY: "hidden", overflowX: "hidden" }}
      style={{ padding: 0, height: "100vh", overflowY: "scroll" }}
    >
      <MetaPixel />
      {/* {authUser ? (
        <>
          <Header accountName="" accountTitle="" companyName="" />
          <SideBar
            setSideBar={setSideBar}
            sideBar={sideBar}
            setAct2={setAct2}
            act2={act2}
            authUser={JSON.parse(user)}
          ></SideBar>
        </>
      ) : null} */}
      <Routes location={location}>
        <Route
          exact
          path="/"
          element={
            <ProctedRout
              component={<SendOtp />}
              // user={user}
            />
          }
        />
        <Route
          exact
          path="/login"
          element={
            // <ProctedRout
              <SendOtp />
              // user={user}
            // />
          }
        />
        <Route
          path="truckRecord"
          element={
            <ProctedRout
              component={<TruckRecord />}
              // user={user}
            />
          }
        />
                <Route
          path="AllTruckRecord"
          element={
            <ProctedRout
              component={<AllTruckRecord />}
              // user={user}
            />
          }
        />
                        <Route
          path="OwnerDashboard"
          element={
            <ProctedRout
              component={<OwnerDashboard />}
              // user={user}
            />
          }
        />
        <Route
          path="truckRecord/:id"
          element={
            <ProctedRout
              component={<TruckRecordVehicle />}
              // user={user}
            />
          }
        />
        <Route
          path="CheckFASTagTrnxs/:id"
          element={
            <ProctedRout
              component={<CheckFASTagTrnxs />}
              // user={user}
            />
          }
        />
        <Route
          path="PendingChallans/:id"
          element={
            <ProctedRout
              component={<PendingChallans />}
              // user={user}
            />
          }
        />
        <Route
          path="OtherTruckRecordOptions"
          element={
            <ProctedRout
              component={<OtherTruckRecordOptions />}
              // user={user}
            />
          }
        />
        <Route
          path="CheckMoreVehicles"
          element={
            <ProctedRout
              component={<CheckMoreVehicles />}
              // user={user}
            />
          }
        />
        <Route
          path="allFastagVehicles"
          element={
            <ProctedRout
              component={<AllFastagVehicles />}
              pathName={window.location.pathname}
            />
          }
        />
        <Route
          path="ExpenseCalculator"
          element={
            <ProctedRout
              component={<ExpenseCalculator />}
              pathName={window.location.pathname}
            />
          }
        />

<Route
        path="SearchDrivers"
        element={
          <ProctedRout
            component={<SearchDrivers />}
            pathName={window.location.pathname}
          />
        }
        />

    <Route
        path="driverSearchList/:id"
        element={
          <ProctedRout
            component={<DriverSearchList />}
            pathName={window.location.pathname}
          />
        }
        />

    <Route
        path="driverSearchHistory"
        element={
          <ProctedRout
            component={<SearchHistoryList />}
            pathName={window.location.pathname}
          />
        }
        />

<Route
        exact
        path="/PayDriver/:id"
        element={
          <ProctedRout
            component={<PayDriver />}
            // user={user}
          />
        }
      /> 
      

        {/* Driver Routes###################### */}

        <Route
          exact
          path="/driverLogin"
          element={
<DriverLogin />
              // user={user}

          }
        />
        <Route
          exact
          path="/driverRegister"
          element={
            <ProtectedDriverHoc
              component={<DriverRegister />}
              // user={user}
            />
          }
        />

        <Route
          exact
          path="/driverDashboard"
          element={
            <ProtectedDriverHoc
              component={<DriverDashboard />}
              // user={user}
            />
          }
        />
        <Route
          exact
          path="/verifyTrip/:id"
          element={
            <ProtectedDriverHoc
              component={<VerifyTrip />}
              // user={user}
            />
          }
        />

<Route
          exact
          path="/SearchJobs"
          element={
            <ProtectedDriverHoc
              component={<SearchJobs />}
              // user={user}
            />
          }
        />

        <Route
          exact
          path="/driverEarnings"
          element={
            <ProtectedDriverHoc
              component={<DriverEarnings />}
              // user={user}
            />
          }
        />
      <Route
        exact
        path="/DriverUpdateDetails"
        element={
          <ProtectedDriverHoc
            component={<DriverUpdateDetails />}
            // user={user}
          />
        }
      />     

<Route
        exact
        path="/PaymentMethods"
        element={<PaymentMethods />}
            // user={user}
      />  

              <Route
          exact
          path="/Success/:id"
          element={
            <PaymentSuccessScreen />
          }
        />   

      </Routes> 
    </div>
  );
}
export default memo(MainApp);
