export const TOAST_STATUS = "TOAST_STATUS";
export const TOAST_STATUS_SUCCESS = "TOAST_STATUS_SUCCESS";
export const STORE_MEDIA_FILE = "STORE_MEDIA_FILE";
export const STORE_MEDIA_FILE_SUCCESS = "STORE_MEDIA_FILE_SUCCESS";
export const GET_SIGNED_URL = "GET_SIGNED_URL";
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";


export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const CLEAN_LOGIN_LOADER = "CLEAN_LOGIN_LOADER";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP = "VERIFY_OTP";
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const TRIP_FORCAST = "TRIP_FORCAST";
export const TRIP_FORCAST_SUCCESS = "TRIP_FORCAST_SUCCESS" 

export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS";
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS" 
export const GET_VEHICLE_CHALLAN = "GET_VEHICLE_CHALLAN";
export const GET_VEHICLE_CHALLAN_SUCCESS = "GET_VEHICLE_CHALLAN_SUCCESS" 
export const CLEAN_VEHICLE_CHALLAN_SUCCESS = "CLEAN_VEHICLE_CHALLAN_SUCCESS" 


export const GET_FASTAG_TXN = "GET_FASTAG_TXN";
export const GET_FASTAG_TXN_SUCCESS = "GET_FASTAG_TXN_SUCCESS" 
export const CLEAN_FASTAG_TXN = "CLEAN_FASTAG_TXN";
export const SEND_OTP_FASTAG = "SEND_OTP_FASTAG";
export const SEND_OTP_FASTAG_SUCCESS = "SEND_OTP_FASTAG_SUCCESS" 
export const VERIFY_OTP_FASTAG = "VERIFY_OTP_FASTAG";
export const VERIFY_OTP_FASTAG_SUCCESS = "VERIFY_OTP_FASTAG_SUCCESS" 
export const GET_ALL_VEHICLES = "GET_ALL_VEHICLES";
export const GET_ALL_VEHICLES_SUCCESS = "GET_ALL_VEHICLES_SUCCESS" 



//VEHICLE
//TODO: PLEASE ADD ALL DRIVER ROUTE HERE...........
export const SEARCH_DRIVER = "SEARCH_DRIVER";
export const SEARCH_DRIVER_SUCCESS = "SEARCH_DRIVER_SUCCESS" 
export const SEARCH_DRIVER_HISTORY = "SEARCH_DRIVER_HISTORY";
export const SEARCH_DRIVER_HISTORY_SUCCESS = "SEARCH_DRIVER_HISTORY_SUCCESS" 
export const CLEAN_SEARCH_DRIVER_HISTORY = "CLEAN_SEARCH_DRIVER_HISTORY" 
export const DRIVER_UPDATE_DETAILS = "DRIVER_UPDATE_DETAILS";
export const DRIVER_UPDATE_DETAILS_SUCCESS = "DRIVER_UPDATE_DETAILS_SUCCESS";
export const SEND_INTEREST = "SEND_INTEREST";
export const SEND_INTEREST_SUCCESS = "SEND_INTEREST_SUCCESS";
export const DRIVER_GET_JOBS = "DRIVER_GET_JOBS";
export const DRIVER_GET_JOBS_SUCCESS = "DRIVER_GET_JOBS_SUCCESS";
export const DRIVER_BID_JOBS = "DRIVER_BID_JOBS";
export const DRIVER_BID_JOBS_SUCCESS = "DRIVER_BID_JOBS_SUCCESS";
export const TRIP_SEND_OTP = "TRIP_SEND_OTP";
export const TRIP_SEND_OTP_SUCCESS = "TRIP_SEND_OTP_SUCCESS";
export const TRIP_VERIFY_OTP = "TRIP_VERIFY_OTP";
export const TRIP_VERIFY_OTP_SUCCESS = "TRIP_VERIFY_OTP_SUCCESS";
export const TRIP_START = "TRIP_START";
export const TRIP_START_SUCCESS = "TRIP_START_SUCCESS";
export const TRIP_END = "TRIP_END";
export const TRIP_END_SUCCESS = "TRIP_END_SUCCESS";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";

export const TRIP_PAYMENT_HISTORY = "TRIP_PAYMENT_HISTORY";
export const TRIP_PAYMENT_HISTORY_SUCCESS = "TRIP_PAYMENT_HISTORY_SUCCESS";
