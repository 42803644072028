import NetworkClient from "./NetworkClient";
import {
  BASE_URL,
  LOGIPE_BASE_URL,
  LOGIPE_LITE
} from "./globals";
import { URLS } from "./urls";
import { getDataFromAsyncStorage } from "./functions";
// import { ObjectSchema } from "yup";

export async function Apis() {
  return NetworkClient.get(`${LOGIPE_LITE}${URLS.sendOtp}`);
}

export async function sendOtpApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.sendOtp}`, obj);
}
export async function verifyOtpApi(obj) {
  console.log(obj);
  return NetworkClient.post(`${BASE_URL}${URLS.verifyOtp}`, {
    phone: obj.phone,
    otp: obj.otp,
  });
}
export async function getCitiesApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.get(`${LOGIPE_BASE_URL}${URLS.getCities}?lat=${
      obj?.lat || ""
    }&long=${obj?.long || ""}&search=${obj?.search || ""}`);
}

export async function tripForcastApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripForcast}`,obj);
}

export async function getVehicleDetailsApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getVehicleDetails}`,obj);
}
export async function getVehicleChallanApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getVehicleChallan}`,obj);
}
export async function getFastagTxnApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getFastagTxn}`,obj);
}
export async function sendOtpFastagApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.sendOtpFastag}`,obj);
}
export async function verifyOtpFastagApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.verifyOtpFastag}`,obj);
}
export async function getAllVehicleApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getAllVehicle}`,obj);
}
export async function searchDriverApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.searchDriver}`,obj);
}
export async function searchDriverHistoryApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.searchDriverHistory}`,obj);
}

export async function storeMediaFileApi(obj) {
  console.log("file dt", obj);
  const img = obj?.img;
  const isRes = obj?.isRes;
  const body = new FormData();
  let tempToken = obj?.token;
  body.append("file", img);

  //console.log('filee',img?.split("/")[img.split("/")?.length - 1])

  //console.log("filee",body);
  if (isRes) {
    let token = await getDataFromAsyncStorage("_token");
    if (!token) {
      token = await getDataFromAsyncStorage("_temptoken");
    }
    if (tempToken) {
      token = tempToken;
    }
    return fetch("https://gke-api.logipe.com/api/api/v1/media/upload", {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        otherHeader: "foo",
        "x-access-token": token,
        requesttype: "newRegistration",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log("isRes error", e);
        return e;
      });
  } else {
    let token = await getDataFromAsyncStorage("_token");
    if (tempToken) {
      token = tempToken;
    }
    return fetch("https://gke-api.logipe.com/api/api/v1/media/upload", {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        otherHeader: "foo",
        "x-access-token": token,
      },
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("res in api", res);
        return res;
      })
      .catch((e) => {
        console.log("no Res error", e);
        return e;
      });
  }
}

export async function getSignedUrlApi(payload) {
  return NetworkClient.post(`${"https://gke-api.logipe.com/api/api/v1/"}${URLS.GET_SIGNED_URL}`, payload);
}


export async function driverUpdateDetailsApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.driverUpdateDetails}`,obj);
}

export async function driverSendInterestApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.driverSendInterest}`,obj);
}

export async function driverGetJobsApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.driverGetJobs}`,obj);
}

export async function driverBidJobsApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.driverBidJobs}`,obj);
}


export async function tripSendOtpApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripSendOtp}`,obj);
}
export async function tripVerifyOtpApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripVerifyOtp}`,obj);
}
export async function tripStartApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripStart}`,obj);
}
export async function tripEndApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripEnd}`,obj);
}
export async function createOrderApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.createOrder}`,obj);
}

export async function tripPaymentHistoryApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripPaymentHistory}`,obj);
}