import {
  LOGIN,
  SEND_OTP,
  VERIFY_OTP,
} from "../storeTypes";
import {
  loginSuccess,
  sendOtpSuccess,
  verifyOtpSuccess,
} from "../actions/LoginAction";
import {
  getDataFromAsyncStorage,
  storeDataToAsyncStorage,
  processErrorAndRespond
} from "../../Utils/functions";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  sendOtpApi,
  verifyOtpApi,
  getOwnerAccountDetails,
  getOwnerProfileForEdit,
  apiLoginRequest,loginWithPasswordApi, resetPasswordApi
} from "../../Utils/Apis";

function* sendOtpSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(sendOtpApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(sendOtpSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* verifyOtpSaga(action) {
  try {
    console.log("verifying", action.verifyObj);
    const response = yield call(verifyOtpApi, action.verifyObj);
    console.log("resp check", response);
    if (response?.status == 200) {
      console.log("sagA", response?.data);
      yield put(verifyOtpSuccess(response?.data));
      action?.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action?.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action?.callBack(error?.response);
    }
    console.log("Error.Response verifyOtpSaga:- ", error.response?.data);
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}





export function* watchAuthSagas() {
  yield takeEvery(SEND_OTP, sendOtpSaga);
  yield takeEvery(VERIFY_OTP, verifyOtpSaga);
}
