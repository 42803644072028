import { all } from "redux-saga/effects";
import { appSaga } from "./appSaga";
import { watchAuthSagas } from "./loginSaga";
import { watchTripSagas } from "./TripSaga";
import { watchDriverSaga } from "./driverSaga";


export default function* rootSaga() {
  yield all([
    appSaga(),
    watchAuthSagas(),
    watchTripSagas(),
    watchDriverSaga()
  ]);
}
