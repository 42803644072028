import React, { useEffect } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { cleanVehicleChallan, getVehicleChallan } from "../../store/actions/TripsAction";
import { useLocation, useParams } from "react-router-dom";
import SvgImage from "../../Core/Icons";
import BottomBox from "../../SubComponents/BottomBox";

function PendingChallans(props) {
  const { vrn } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();

  let {id} = useParams();
  const { vehicleChallan } = useSelector((state) => state?.trip);
  console.log("jsrfjr", vehicleChallan);

  useEffect(() => {
    console.log("sjrofojrs");
    dispatch(cleanVehicleChallan())
    if(id){
      if (state?.vrn) {
        dispatch(getVehicleChallan({ vrn: state?.vrn }, (resp) => {}));
      }
    }
  }, [id]);

  // vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data

  console.log("enmkrjie", vehicleChallan?.data?.response,vehicleChallan);
  // Disposed_data
  return (
    <div className="">
      <Header />


      {vehicleChallan?.data == false ? <div>OOPS... SERVER DOWN</div> : !vehicleChallan?.data?.response ? (
      <div style={{margin : "50px 0 0 0"}}>
      <SvgImage name = "AppIconLoading" />

      </div>
      ) : (
        <>
          {" "}
          <div
            className="d-flex flex-column"
            style={{ margin: "50px 0 0 0", gap: "10px" }}
          >
            <div className="FontNB24">{state?.vrn}</div>
            <div className="FontNB24">
              {vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data
                ?.length || 0}{" "}
              Pending Challans !
            </div>
          </div>
          <div className="txnDetalis" style={{ marginTop: "30px" }}>
            {vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data
              ?.length > 0 &&
              vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data?.map(
                (item, idx) => {
                  return (
                    <div className="d-flex" style={{justifyContent : "space-between",    border: "1px solid rgba(108, 108, 108, 0.24)"}}>
                      <div  style={{width : "70%"}}>
                        <div className="fontNM18">{item?.challan_no}</div>
                        <div className="fontNM18" style={{  color: "#6c6c6c"}}>
                          {item?.challan_date_time} || {item?.challan_place}
                        </div>
                      </div>
                      <div style={{width : "30%"}}>
                        <div className="font_20" style={{ fontWeight: "bold" }}>
                          ₹ {item?.fine_imposed}
                        </div>
                        <div
                          className="paidbtn font_20 col-lg-6"
                          style={{
                            marginRight: "10px",
                            float: "right",
                            marginTop: "10px",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            console.log("mkrfmer",item?.challan_status)
                            if (item?.challan_status == "Pending") {
                              navigator.clipboard
                                .writeText(item?.challan_no)
                                .then(() => {
                                  window.open(
                                    "https://echallan.parivahan.gov.in/index/accused-challan",
                                    "_blank"
                                  );
                                })
                                .catch((err) => {
                                  console.error(
                                    "Failed to copy challan number:",
                                    err
                                  );
                                });
                            }
                          }}
                        >
                          <div
                            className="paidbtnFont"
                            style={{
                              background:
                                item?.challan_status == "Pending"
                                  ? "black"
                                  : "3eb655",
                              cursor:
                                item?.challan_status == "Pending"
                                  ? "pointer"
                                  : "",
                            }}
                          >
                            {item?.challan_status == "Pending"
                              ? "Pay Now"
                              : "Paid"}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
          <div className="txnDetalis">
            {vehicleChallan?.data?.response?.[0]?.response?.data?.Disposed_data
              ?.length > 0 &&
              vehicleChallan?.data?.response?.[0]?.response?.data?.Disposed_data?.map(
                (item, idx) => {
                  return (
                    <div className="d-flex paid-main-box">
                      <div className="paid-txt" style={{width : "70%"}}>
                        <div className="font_19">{item?.challan_no}</div>
                        <div className="fastagBoxDesc">
                          {item?.challan_date_time} || {item?.challan_place}
                        </div>
                      </div>
                      <div className="" style={{width : "30%"}}>
                        <div className="font_20" style={{ fontWeight: "bold" }}>
                          ₹ {item?.fine_imposed}
                        </div>
                        <div
                          className="paidbtn font_20 col-lg-6"
                          style={{
                            marginRight: "10px",
                            float: "right",
                            marginTop: "0px",
                            background: "#3eb655",
                            borderRadius: "7px"
                          }}
                        >
                          <div className="paidbtnFont">
                            {"Paid"}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
          <div>
          <BottomBox icon1 = {"expenseCal"} icon2 = {"searchDriver"} vrn={state?.vrn}/>

          </div>

        </>
      )}
    </div>
  );
}

export default PendingChallans;
