import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../store/actions/LoginAction";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../Utils/functions";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../Core/Icons";
import { DatePickerField } from "../../Core/DatePicker/input";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { storeMedia } from "../../store/actions/appActions";
import DriverHeader from "../../SubComponents/DriverHeader";
import BidModal from "../../SubComponents/BidModal";
import StartTripModal from "../../SubComponents/StartTripModal";
import { driverGetJobs, searchDriverHistory, tripSendOtp } from "../../store/actions/driverAction";
import moment from "moment";
import { motion } from "framer-motion";
import { Autocomplete, TextField } from "@mui/material";
import { getAllVehicle } from "../../store/actions/TripsAction";
import BottomBox from "../../SubComponents/BottomBox";
import img11 from "../../assets/images/image11.png"
import { socket, SocketContext } from "../../Utils/socket";
import { io } from "socket.io-client";
//toast.configure()
function OwnerDashboard(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");
  const dispatch = useDispatch();

  const [reload, setReload] = useState();

  const [selectedHero, setSelectedHero] = useState(1);
  const [selectedHero1, setSelectedHero1] = useState(1);


  const [openBidModal, setOpenBidModal] = useState(false);
  const [startTripModal, setStartTripModal] = useState(false);
  const [jobData, setJobData] = useState(null);

  let driver = JSON.parse(sessionStorage.getItem("_user"));
  console.log("oejroj", driver);

  let owner = JSON.parse(sessionStorage.getItem("_user"));
  console.log("oejroj", owner);

  const [locationFetch, setLocationFetch] = useState(null);

  const { vehicleDetails, allFastagVehcile } = useSelector(
    (state) => state?.trip
  );

  const handleInviteAccepted = useCallback(() => {
    console.log("connectingggggg")
  }, []);

  // let socket;
  let socket1;
  global.socket={on : (text,callback) => {
    console.log("socket is not working")
  }}
  
  useEffect(() => {
    let token2 = sessionStorage.getItem("_token");
    token2 = JSON.parse(token2);
    if(token2){
      socket1 = io("https://gke-socket.logipe.com/", {
            query: { token: token2 },
            transports: ["websocket"],
        }); 
        
        socket1.on("connect", (data) => {
          console.log("connect socket");
          global.socket = socket1;
        });
    }
    
  },[])

  useEffect(() => {
    dispatch(
      getAllVehicle(
        {
          limit: 2,
        },
        (resp) => {
            console.log("msrmf",resp?.data?.data)
            if(resp?.data?.data?.count == 0){
                navigate("/truckRecord")
            }
        }
      )
    );
  }, []);

  const [loader1, setLoader1] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(
      () =>
        setSelectedHero((prevIndex) =>
          prevIndex+1 == allFastagVehcile?.length+1 ? 1 : prevIndex + 1,
        ),
      5000,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedHero]);
  
    useEffect(() => {
    let timeout = setTimeout(
      () =>
        setSelectedHero1((prevIndex) =>
          prevIndex+1 == driverSearchHistoryList?.length+1 ? 1 : prevIndex + 1,
        ),
      3000,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedHero1]);
  const fadeInRightVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
  };

  useEffect(() => {
    console.log("sjrofojrs");
      dispatch(searchDriverHistory({
        limit : 2
      }, (resp) => {}));
  }, []);


const {driverSearchHistoryList} = useSelector(state => state?.driver)
console.log("dojrofj",driverSearchHistoryList)

  return (
    <div className="">
      <DriverHeader />
      <div
        className="d-flex flex-column ukpk"
        style={{ margin: "50px 0 0 0", gap: "10px", padding: "0 7% 0 7%" }}
      >
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div style={{ textAlign: "left" }} className="fontNB24">
            Namaskar, {driver?.name || "N/A"}
          </div>
        </div>

        {!allFastagVehcile ? (
          <div style={{ margin: "50px 0 0 0" }}>
            <SvgImage name="AppIconLoading" />
          </div>
        ) : (
          <>
            {" "}
            {allFastagVehcile?.length > 0 ? (
              <>
                <div style={{ textAlign: "left" }} className="fontNB24 mt-2">
                  Vehicles
                </div>
                <div className="d-flex">
                  <div style={{width : "80%"}}>
                    {allFastagVehcile?.map((item, index) => {
                      return (
                        <>
                          {index + 1 == selectedHero ? (
                            <motion.div
                              className="grey-box-mini d-flex"
                              variants={fadeInRightVariants}
                              initial="hidden"
                              animate="visible"
                              exit="exit"
                              style={{
                                justifyContent: "space-between",
                                background:
                                  "linear-gradient(91.33deg, rgba(0, 0, 0, 0.2) 1.24%, rgba(212, 213, 214, 0.2) 60.37%)",
                              }}
                            >
                              <div
                                className="d-flex flex-column"
                                style={{
                                  alignItems: "flex-start",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="fontNB20"> {item?.vrn}</div>
                                <div className="fontNM12">
                                  All Records Updated
                                </div>
                              </div>
                              <div style={{ textDecoration: "underline",cursor:"pointer" }} onClick={() => navigate(`/truckRecord/${item?.vrn?.toUpperCase()?.replace(/ /g,'')}`)}>
                                Check Details
                              </div>
                            </motion.div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                    <ul className="slick-dots mt-2" role="tablist">
                      {allFastagVehcile?.map((section, indx) => (
                        <li
                          key={`${selectedHero}-${indx + 1}`}
                          className={
                            selectedHero === indx + 1 ? "slick-active" : ""
                          }
                          onClick={() => setSelectedHero(indx + 1)}
                        >
                          <button type="button" role="button">
                            {indx + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div style={{width : "20%",cursor : "pointer"}} className="FontNB40" onClick={() => navigate("/truckRecord")}>
                    +
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}

{!driverSearchHistoryList ? (
          <div style={{ margin: "50px 0 0 0" }}>
            <SvgImage name="AppIconLoading" />
          </div>
        ) : (
          <>
            {" "}
            {driverSearchHistoryList?.length > 0 ? (
              <>
                <div style={{ textAlign: "left" }} className="fontNB24 mt-2">
                Hire Driver Requests
                </div>
                <div className="d-flex">
                  <div style={{width : "80%"}}>
                    {driverSearchHistoryList?.map((item, index) => {
                      return (
                        <>
                          {index + 1 == selectedHero1 ? (
                            <motion.div
                              className="grey-box-mini d-flex"
                              variants={fadeInRightVariants}
                              initial="hidden"
                              animate="visible"
                              exit="exit"
                              style={{
                                justifyContent: "space-between",
                                background:
                                  "linear-gradient(91.33deg, rgba(0, 0, 0, 0.2) 1.24%, rgba(212, 213, 214, 0.2) 60.37%)",
                              }}
                            >
                              <div
                                className="d-flex flex-column"
                                style={{
                                  alignItems: "flex-start",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="fontNB20" style={{textAlign : "left"}}> {item?.from} - {item?.to} </div>
                                <div className="fontNM12">
                                  {item?.bidCount || 0} Driver Accepted Requests
                                </div>
                              </div>
                              <div style={{ textDecoration: "underline",cursor:"pointer"  }} onClick={() => navigate(`/driverSearchList/${item?.searchId}`)}>
                                Check Details
                              </div>
                            </motion.div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                    <ul className="slick-dots mt-2" role="tablist">
                      {driverSearchHistoryList?.map((section, indx) => (
                        <li
                          key={`${selectedHero1}-${indx + 1}`}
                          className={
                            selectedHero1 === indx + 1 ? "slick-active" : ""
                          }
                          onClick={() => setSelectedHero1(indx + 1)}
                        >
                          <button type="button" role="button">
                            {indx + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div style={{width : "20%",cursor : "pointer"}} className="FontNB40" onClick={() => navigate("/SearchDrivers")}>
                    +
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <BottomBox icon1 = {"expenseCal"} icon2 = {"searchDriverList"} icon3 = {"truckRecord"}/>

      </div>

      {openBidModal ? (
        <BidModal
          toggle={() => setOpenBidModal(!openBidModal)}
          modal={true}
          setModal={setOpenBidModal}
          jobData={jobData}
          setReload={setReload}
          reload={reload}
        />
      ) : (
        <></>
      )}

      {startTripModal ? (
        <StartTripModal
          toggle={() => setStartTripModal(!startTripModal)}
          modal={startTripModal}
          setModal={setStartTripModal}
          jobData={jobData}
          setReload={setReload}
          reload={reload}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default OwnerDashboard;
