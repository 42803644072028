import { 
  SEND_OTP,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  REQUEST_LOGIN_SUCCESS,
  // CLEAN_LOGIN_LOADER,
  // CLEAR_AUTH_STORE
 } from "../storeTypes";




export function sendOtp(obj, callBack) {
  console.log("otp-> sdsdsdsdsdsd",obj);
  return {
    type: SEND_OTP,
    payload:obj,
    callBack
  };
}

export function sendOtpSuccess(msg) {
  return {type: SEND_OTP_SUCCESS, payload: msg};
}

export function verifyOtp(verifyObj, callBack) {
  return {
    type: VERIFY_OTP,
    verifyObj,
    callBack,
  };
}

export function verifyOtpSuccess(data) {
  return {type: VERIFY_OTP_SUCCESS, payload:data};
}

export function handleLoginSuccess(token) {
  return {type: REQUEST_LOGIN_SUCCESS, payload: token};
}




