import React, { useEffect, useState } from "react";
import SvgImage from "../../Core/Icons";
import { useMediaQuery } from "@mui/material";
import "./index.css"

//toast.configure()
function Header(props) {

    const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className="" style={{width : "100%", position:"relative"}}>
        <SvgImage name = "ecllipse" width={isMobile ? "100%" : "100%"} />
        <div className="app-Name fontNB45">
        <SvgImage name = "AppIcon" />
        </div>
        {/* <div className="app-Name-1 fontNB20">
        Vehicle & Driver Management Platform
        </div> */}
    </div>
  );
}
export default Header;
