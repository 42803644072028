import React, { useEffect, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch } from "react-redux";
import { handleLoginSuccess, sendOtp, verifyOtp } from "../../store/actions/LoginAction";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../Utils/functions";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../Core/Icons";

//toast.configure()
function DriverLogin(props) {
  const { state } = useLocation();
  console.log("jojoejw",state)
  const navigate = useNavigate()
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");

  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState("");

  const handleSendOtp = () => {
    setLoader(true)
    dispatch(sendOtp({phone},(res) => {
      console.log("nanrennnn",res);
      setLoader(false)
      if(res?.status == 200){
        isSetSendOtp("1");
        // toast.success("OTP Send Successfully")
      }
    }))
  }

  const handleVerifyOtp = () => {
    setLoader(true)
    dispatch(verifyOtp({phone,otp,userType : 1},(res) => {
      console.log("nanrennnn",res);
      setLoader(false)
      if(res?.status == 200 && res?.data?.data){
        storeDataToAsyncStorage("_token", res.data?.data.token);
        if(res.data?.data.driver) storeDataToAsyncStorage("_driver", res.data?.data.driver);
        storeDataToAsyncStorage("_user", res.data?.data);

        storeDataToAsyncStorage(
          "_refreshToken",
          res.data?.data.refreshToken
        );

        //dispatch(getOwnerAccountDetails(),res => {});
        dispatch(handleLoginSuccess(res.data?.data));
        //dispatch(getOwnerDetails());

        //TODO:
        if( !res?.data?.data?.driver || res?.data?.data?.driver == {} ) navigate("/driverRegister");
        else navigate(state?.pathName || "/driverDashboard");
        //--------------------

        // isSetSendOtp("0");
        // navigate(state?.pathName)
        // toast.success("OTP Send Successfully")
      }
    }))
  }


  return (
    <div className="">
      <Header />
      {isSendOtp == "0" ? (
        <div
          className="d-flex flex-column"
          style={{ margin: "50px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">Register & Get Higher Pay</div>
          <div className="FontNS20">Phone Number</div>
          <div>
            <input type="text" className="inputBoxAddCust" inputMode= 'numeric' value={phone} onChange={(e) => {
              const phoneRegex = /^[6-9]\d{9}$/;
              setPhone(e.target?.value)
              if (!phoneRegex.test(e.target?.value)) {
                setPhoneError("Please provide valid phone number")
              }
              else {
                setPhoneError("")
              }
            }} />
          </div>
          {phoneError && <span className="error1">{phoneError}</span>}
          <div className="addCustcenter" style={{ margin: "30px 0 0 0" }} onClick={() => handleSendOtp()}>
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={() => setSendOtp(1)}
            >
              <div className="AddCust">{loader ?                        <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Send OTP"}</div>
            </button>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column"
          style={{ margin: "50px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">Register with us</div>
          <div className="">
            <div className="col-12">
              <label for="vrn" className="heading3 semiBold">
                Enter OTP
              </label>
            </div>
            <div className="col-12" style={{ padding: "8px" }}>
              <PinInput
                name="pin"
                length={4}
                initialValue=""
                secret
                onChange={(value, index) =>
                  setOtp(value)
                }
                type="numeric"
                inputMode= 'numeric'                
                style={
                  {
                    //   padding: "10px",
                    //   marginLeft: "96px",
                    //   marginTop: "28px",
                  }
                }
                inputStyle={{
                  border: "1px solid #A7A7A7",
                  borderRadius: "8px",
                  marginRight: "5px",
                  width: "35px",
                  height: "35px",
                }}
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <div
                style={{
                  margin: "7px 0 0px 5px",
                  color: "red",
                }}
              ></div>
            </div>
            <div>
            OTP sent on +91 {phone}
              </div>
          </div>
          <div className="addCustcenter" style={{ margin: "30px 0 0 0" }} onClick={() => handleVerifyOtp()}>
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={toggleCreateCust}
            >
              <div className="AddCust">{loader ?                        <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Verify OTP"}</div>
            </button>
          </div>
        </div>
      )}

      <div className="d-flex flex-column" style={{marginTop : "30px"}}>
        <div className="d-flex" style={{gap : "20px",marginBottom : "10px"}}>
          <div className="ExtraInfo">
            <SvgImage name = "MoneyIcon" />
          </div>
          <div className="d-flex flex-column" style={{alignItems: "flex-start",marginRight: "10px"}}>
            <div className="Extra-Info-Text fontNB20">
            Increased Trip Payment
            </div>
            <div className=" Extra-Info-Text fontNM12" style={{textAlign :"left"}}>
            Get to give your own Payment demand against Best Offers.
            </div>
          </div>
        </div>
        <div className="d-flex" style={{gap : "20px",marginBottom : "10px"}}>
          <div className="ExtraInfo">
            <SvgImage name = "DriverTruckLocation" />
          </div>
          <div className="d-flex flex-column" style={{alignItems: "flex-start",marginRight: "10px"}}>
            <div className="Extra-Info-Text fontNB20">
            Drive as per your Wish
            </div>
            <div className=" Extra-Info-Text fontNM12" style={{textAlign :"left"}}>
            Pick Jobs as per your availability in Location or Time.
            </div>
          </div>
        </div>
        <div className="d-flex" style={{gap : "20px",marginBottom : "10px"}}>
          <div className="ExtraInfo">
            <SvgImage name = "onTimeIcon" />
          </div>
          <div className="d-flex flex-column" style={{alignItems: "flex-start",    marginRight: "10px"}}>
            <div className="Extra-Info-Text fontNB20">
            On-Time Payments
            </div>
            <div className=" Extra-Info-Text fontNM12" style={{textAlign :"left"}}>
            On-time Payments in your account on Trip Start and End.
            </div>
          </div>
        </div>
        <div className="d-flex" style={{gap : "20px",marginBottom : "10px"}}>
          <div className="ExtraInfo">
            <SvgImage name = "AccidentIcon" />
          </div>
          <div className="d-flex flex-column" style={{alignItems: "flex-start",marginRight: "10px"}}>
            <div className="Extra-Info-Text fontNB20">
            Accident Insurance
            </div>
            <div className=" Extra-Info-Text fontNM12" style={{textAlign :"left"}}>
            Insurance coverage of Rs 2 Lakh on getting LogiPe Card.
            </div>
          </div>
        </div>
      </div>
     {/* <ToastContainer position="bottom-right" /> */}
    </div>
  );
}
export default DriverLogin;
