import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../../../store/actions/LoginAction";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { getCities, storeMedia } from "../../../../store/actions/appActions";
import DriverHeader from "../../../../SubComponents/DriverHeader";
import BidModal from "../../../../SubComponents/BidModal";
import StartTripModal from "../../../../SubComponents/StartTripModal";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../../../Core/Icons";
import { Autocomplete, TextField } from "@mui/material";
import { driverGetJobs } from "../../../../store/actions/driverAction";
import moment from "moment";
//toast.configure()
function SearchJobs(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const [locationFetch, setLocationFetch] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [openBidModal, setOpenBidModal] = useState(false);
  const [reload, setReload] = useState();

  useEffect(() => {
      let timeout = setTimeout(() => {
        dispatch(getCities({ search: address }));
      }, 750);
      return () => {
        clearTimeout(timeout);
      };  
  }, [address]);
  const [loader1, setLoader1] = useState(false);

  useEffect(() => {
    console.log("mkerd",locationFetch)
    if(locationFetch && locationFetch?.lat && locationFetch?.long){
      dispatch(
        driverGetJobs(
          {
            latitude: locationFetch?.lat,
            longitude: locationFetch?.long,
          },
          (resp) => {
          }
        )
      );
    }
    else{
      setLoader1(true)
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          console.log("msoejfojwe", position);
          dispatch(
            driverGetJobs(
              {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              (resp) => {
                setLoader1(false)

              }
            )
          );
        });
      } else {
        console.log("Geolocation is not available in your browser.");
      } 
    }
  }, [locationFetch,reload]);
//   const [loader,setLoader] = useState("");
  const {cities,loader} = useSelector(state => state?.app)
  const { driverNotInterestedJobs, driverJobsLoader } =
  useSelector((state) => state?.driver);
  console.log("ndjfnk",driverJobsLoader)

  return (
    <div className="">
      <DriverHeader />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 40px 0", gap: "10px", padding: "0 7% 0 7%" }}
      >
        <div style={{ textAlign: "left" }} className="fontNB24">
          Search Jobs
        </div>

        <div
          className="d-flex"
          style={{
            margin: "10px 0 0 0 ",
            width: "100%",
          }}
        >
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <div style={{ textAlign: "left" }}>Search by Location</div>
            <div
              style={{
                width: "100%",
              }}
            >
                    <Autocomplete
                      freeSolo
                      options={cities}
                      // loading={loader}
                      isOptionEqualToValue={(option, value) => {
                        return option?.cityName === value?.cityName;
                      }}
                      getOptionLabel={(item) => {
                        console.log("Tripmkmeromore", item);
                        let reqValue = "";
                        if (item) {
                          reqValue = item?.cityName;
                        }
                        return reqValue;
                      }}
                      onClick={() => setAddress("")}
                      value={locationFetch || ""}
                      onInputChange={(event, newValue) => {
                        console.log("Tripjiejroj", event, newValue);

                        setAddress(newValue);

                        if (!newValue) {
                          // setUserId("");
                          setLocationFetch(null);
                        }
                      }}
                      onChange={(event, newValue) => {
                        // console.log("jdiofjioejr",{User : {fullName: newValue?.User?.fullName,phone : newValue?.User?.phone,userId : newValue?.userId}})
                        setLocationFetch(newValue);
                        // setFieldValue("driverName", newValue, true);
                      }}
                      sx={{
                        // width: '300px',          // Custom width
                        // backgroundColor: '#f0f0f0', // Background color for the input
                        borderRadius: "8px", // Rounded corners
                        "& .MuiInputBase-root": {
                          padding: "0px",
                          margin: 0, // Custom padding inside the input
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "blue", // Custom color for dropdown arrow
                        },
                        "& .MuiAutocomplete-listbox": {
                          backgroundColor: "#fff", // Background color for dropdown options
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            {...params.inputProps}
                            style={{ width: "100%" }}
                            placeholder="To"
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            className="inputBoxAddCust"
                          />
                        </div>
                      )}
                    />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column" style={{    margin: "15px 0 0 0",gap : "10px"
        }}>
        {driverJobsLoader || loader1 ? (
          <div style={{ margin: "50px 0 0 0" }}>
            <SvgImage name="AppIconLoading" />
          </div>
        ) : (
          <>
            {driverNotInterestedJobs?.map((item) => {
              let dif = moment.duration(
                moment(new Date()).diff(
                  moment(item?.createdAt, "YYYY-MM-DD HH:mm:ss")
                )
              );
              let posted1, posted2;

              if (dif?.days())
                posted1 = moment(item?.createdAt).format("DD-MM-YYYY");
              else
                posted2 =
                  (dif?.hours() ? dif?.hours() + "h " : "") +
                  (dif?.minutes()
                    ? dif?.seconds()
                      ? parseFloat(dif?.minutes()) + 1 + "m "
                      : dif?.minutes() + "m "
                    : "");

              return (
                <div
                  className="grey-box-mini-2 d-flex"
                  style={{
                    justifyContent: "space-between",
                    background: "#DEDFD733",
                  }}
                >
                  <div className="d-flex flex-column">
                    <div
                      className="d-flex flex-column"
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className="fontNB20">
                        {item?.from} - {item?.to}
                      </div>
                      <div className="fontNM12">
                        {item?.date} | {item?.days ? item?.days + " Days" : ""}{" "}
                      </div>
                    </div>
                    {item?.driverBid && (
                      <div style={{textAlign : "left"}}>Current Bid : ₹ {item?.driverBid}</div>
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <div className="fontNR10" style={{ textAlign: "end" }}>
                      Posted {posted1 ? "on " + posted1 : posted2 + " ago"}
                    </div>
                    <div
                      className="addCustcenter"
                      style={{ margin: "20px 0 0 0" }}
                      onClick={() => {
                        setJobData(item);
                        setOpenBidModal(true);
                      }}
                    >
                      <button
                        className="greenButton Medium text3 AddCustStartBtn"
                        style={{ padding: "0 20px 0 20px" }}
                      >
                        <div className="fontNM12" style={{ color: "white" }}>
                          Give your Rate
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}


        </div>
      </div>
      {openBidModal ? (
        <BidModal
          toggle={() => setOpenBidModal(!openBidModal)}
          modal={true}
          setModal={setOpenBidModal}
          jobData={jobData}
          setReload={setReload}
          reload={reload}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default SearchJobs;
