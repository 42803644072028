import { call, put, takeEvery } from "redux-saga/effects";
import { driverBidJobsApi, driverGetJobsApi, driverSendInterestApi, driverUpdateDetailsApi, getCitiesApi, searchDriverApi, searchDriverHistoryApi, tripPaymentHistoryApi } from "../../Utils/Apis";
// import { processErrorAndRespond } from "../../Utils/functions";

import { DRIVER_BID_JOBS, DRIVER_GET_JOBS, DRIVER_UPDATE_DETAILS, SEARCH_DRIVER,SEARCH_DRIVER_HISTORY, SEND_INTEREST, TRIP_PAYMENT_HISTORY } from "../storeTypes";
// import { Mixpanel } from "../../../Mixpanel";
import {  } from "../actions/appActions";
import { driverBidJobsSuccess, driverGetJobsSuccess, driverSendInterestSuccess, driverUpdateDetailsSuccess, searchDriverHistorySuccess, searchDriverSuccess, tripPaymentHistorySuccess } from "../actions/driverAction";


function* searchDriverSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(searchDriverApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(searchDriverSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* searchDriverHistorySaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(searchDriverHistoryApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(searchDriverHistorySuccess(response?.data));
      // action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      // action.callBack({
      //   message: error?.response?.data.message,
      // });
    } else {
      // action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* driverUpdateDetailsSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(driverUpdateDetailsApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(driverUpdateDetailsSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* driverSendInterestSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(driverSendInterestApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(driverSendInterestSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* driverGetJobsSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(driverGetJobsApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(driverGetJobsSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* driverBidJobsSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(driverBidJobsApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(driverBidJobsSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}


function* tripPaymentHistorySaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(tripPaymentHistoryApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(tripPaymentHistorySuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}


export function* watchDriverSaga() {
  yield takeEvery(SEARCH_DRIVER, searchDriverSaga);
  yield takeEvery(SEARCH_DRIVER_HISTORY, searchDriverHistorySaga);

  yield takeEvery(DRIVER_UPDATE_DETAILS, driverUpdateDetailsSaga);
  yield takeEvery(DRIVER_GET_JOBS, driverGetJobsSaga);
  yield takeEvery(SEND_INTEREST, driverSendInterestSaga);
  yield takeEvery(DRIVER_BID_JOBS, driverBidJobsSaga);
  yield takeEvery(TRIP_PAYMENT_HISTORY, tripPaymentHistorySaga);

  
}
