import React from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendOtpFastag } from "../../store/actions/TripsAction";

function OtherTruckRecordOptions() {
  const { state } = useLocation();
  const {vrn} = state;
  const navigate = useNavigate()
  const dispatch = useDispatch();

  return (
    <div>
      <div className="">
        <Header />

        <div
          className="d-flex flex-column"
          style={{ margin: "120px 0 0 0", gap: "10px" }}
        >
          <button className="purpleBtnCenter" onClick={() => navigate("/truckRecord")}>Check Another Vehicle</button>
          <div className="orLine">OR</div>
          <button className="purpleBtnCenter" onClick={() => {
            dispatch(sendOtpFastag({vrn : vrn},(resp) => {
              console.log("jsjeodfsde",vrn,resp)
              if(resp?.status == 200 && resp?.data?.data?.statusCode == 1003){
                //TODO MSG   resp?.data?.data?.message
                navigate("/CheckMoreVehicles",{state : {vrn : vrn,requestId : resp?.data?.data?.response?.otp,msg : resp?.data?.data?.message}})
              }
            }))
            }}>
            Fetch All Vehicles from NETC
          </button>
          <div className="orLine">OR</div>
          <button className="purpleBtnCenter">Add Vehicles via Excel</button>
        </div>
      </div>
    </div>
  );
}

export default OtherTruckRecordOptions;
