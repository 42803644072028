import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import "./index.css";
import { Slider, styled } from "@mui/material";
import SvgImage from "../../Core/Icons";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { driverBidJobs } from "../../store/actions/driverAction";

const PrettoSlider = styled(Slider)({
  color: "rgba(124, 124, 129, 1)",
  height: 5,
  width: "100%",
  borderRadius: "0px",
  "& .MuiSlider-track": {
    border: "none",
    //   borderRadius : "0px"
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "rgba(0, 0, 0, 1)",
    border: "2px solid rgba(0, 0, 0, 1)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function BidModal(props) {
  const {
    toggle,
    modal,
    setModal,
    jobData,setReload,
    reload
  } = props;
  const handleClose = () => {
    setModal(!modal);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [bidAmount,setBidAmount] = useState(jobData?.bidAmount)
  console.log("lsmfmomr",jobData);
  const dispatch = useDispatch();

  const BidAmountFunc = () => {
    console.log("kdofkjoer")
    dispatch(driverBidJobs({searchId : jobData?.searchId,bidAmount : bidAmount},(response) => {
        console.log("kdofkjoer",response)
        if(response?.status == 200){
            setReload(!reload)
            handleClose()
        }
    }))
  }
  return (
    <div className="overlay">
      <div
        className="popup-Images widthpopUP"
        style={{ width: isMobile ? "75%" : "30%" }}
      >
        <div className="closeBtnWithBackground" onClick={handleClose}>
          <SvgImage name="crossBtnWIthBackground" />
        </div>
        <div className="d-flex flex-column">
          <div className="fontNB35">Give Your BID!</div>
          <div className="fontNM16" style={{ color: "#1E1E1E" }}>
            Give the Rate you want for this trip
          </div>
          <div className="fontNB24" style={{ color: "#454545" }}>
            {jobData?.from} - {jobData?.to}
          </div>
          <div className="d-flex flex-column fontNM16">
            <div>Start - {jobData?.date}</div>
            <div>Time - {jobData?.days ? jobData?.days + " Days" : ""}</div>
          </div>
          <div className="fontNB16">Rate for trip</div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div className="slider-bar"></div>
            <div style={{ width: "80%" }}>
              <PrettoSlider
                valueLabelDisplay="auto"
                value={bidAmount || 0}
                onChange={(event, newValue) => {
                    setBidAmount(newValue);
                  //   setFieldValue("driverSalary",newValue,true)
                  //   setDriverSalaryAmt(newValue*parseFloat(totalDistance || 0)?.toFixed(0) || 0)
                }}
                aria-label="pretto slider"
                defaultValue={jobData?.bidAmount || 0}
                marks={[
                  {
                    value: jobData?.bidAmount - jobData?.bidAmount * 0.2 || 0,
                    label: jobData?.bidAmount - jobData?.bidAmount * 0.2,
                  },
                  {
                    value: jobData?.bidAmount + jobData?.bidAmount * 0.2 || 0,
                    label: jobData?.bidAmount + jobData?.bidAmount * 0.2,
                  },
                ]}
                min={jobData?.bidAmount - jobData?.bidAmount * 0.2 || 0}
                max={jobData?.bidAmount + jobData?.bidAmount * 0.2 || 0}
                step={50}
              />
            </div>

            <div className="slider-bar"></div>
          </div>
          <div className="addCustcenter" style={{ margin: "30px 0 0 0" }} onClick={() => BidAmountFunc()}>
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
            >
              <div className="AddCust">Send Bid</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidModal;
