
import React from 'react';
import { motion } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';

// Animation Variants
const containerVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1, 
    transition: { delay: 0.3, duration: 0.5 }
  },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } }
};

const iconVariants = {
  hidden: { pathLength: 0, rotate: 0 },
  visible: {
    pathLength: 1,
    rotate: 360,
    transition: { duration: 1, ease: "easeInOut" }
  }
};


const PaymentSuccessScreen = () => {
    const {id} = useParams()
  return (
    <motion.div
      className="payment-success-container"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#f0f9ff',
      }}
    >
      {/* Animated Check Icon */}
      <motion.div
        variants={iconVariants}
        initial="hidden"
        animate="visible"
        style={{ marginBottom: '20px' }}
      >
        <CheckCircleIcon sx={{ fontSize: '6rem', color: 'green' }} />
      </motion.div>

      {/* Success Text */}
      <Typography variant="h4" gutterBottom style={{ color: '#333' }}>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom style={{ color: '#555', marginBottom: '20px' }}>
        Thank you!! Your transaction has been completed.
      </Typography>

      {/* CTA Button */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <Button
          component={Link}
          to={`/driverSearchList/${id}`}
          variant="contained"
          color="primary"
          style={{ padding: '10px 20px', fontSize: '1rem' }}
        >
          Continue
        </Button>
      </motion.div>
    </motion.div>
  );
};

export default PaymentSuccessScreen;