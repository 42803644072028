import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import "./index.css";
import { Slider, styled } from "@mui/material";
import SvgImage from "../../Core/Icons";
import { useMediaQuery } from "@mui/material";
import PinInput from "react-pin-input";
import { useDispatch } from "react-redux";
import { tripVerifyOtp } from "../../store/actions/driverAction";

const PrettoSlider = styled(Slider)({
  color: "rgba(124, 124, 129, 1)",
  height: 5,
  width: "100%",
  borderRadius: "0px",
  "& .MuiSlider-track": {
    border: "none",
    //   borderRadius : "0px"
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "rgba(0, 0, 0, 1)",
    border: "2px solid rgba(0, 0, 0, 1)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function StartTripModal(props) {
  const {
    modal,
    setModal,
    jobData,
    setReload,
reload
  } = props;
  const handleClose = () => {
    setModal(!modal);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [otp,setOtp] = useState()

  console.log("lsmfmomr");
  const dispatch = useDispatch();

  const verifyOtp = () => {
    console.log("kdofkjoer")
    dispatch(tripVerifyOtp({searchId : jobData?.searchId,otp : otp},(response) => {
        console.log("kdofkjoer",response)
        if(response?.status == 200){
            setReload(!reload)
            handleClose()
        }
    }))
  }

  return (
    <div className="overlay">
      <div
        className="popup-Images widthpopUP"
        style={{ width: isMobile ? "75%" : "30%" }}
      >
        <div className="closeBtnWithBackground" onClick={handleClose}>
          <SvgImage
            name="crossBtnWIthBackground"
          />
        </div>
        <div className="d-flex flex-column">
          <div className="fontNB24" style={{ color: "#454545" }}>
          {jobData?.from} - {jobData?.to}
          </div>
          <div className=" fontNM16">
          {jobData?.date} | {jobData?.days ? jobData?.days + " Days" : ""}
          </div>
          <div className=" fontNM16 mt-5" >
          Enter OTP shared to Driver
          </div>
          <PinInput
                name="pin"
                length={4}
                initialValue=""
                secret
                onChange={(value, index) =>
                  setOtp(value)
                }
                type="numeric"
                inputMode="numeric"
                style={
                  {
                    //   padding: "10px",
                    //   marginLeft: "96px",
                    //   marginTop: "28px",
                  }
                }
                inputStyle={{
                  border: "1px solid #A7A7A7",
                  borderRadius: "8px",
                  marginRight: "5px",
                  width: "35px",
                  height: "35px",
                }}
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
          <div className="addCustcenter" style={{ margin: "30px 0 0 0" }} onClick={verifyOtp}>
              <button
                className="greenButton Medium text3 AddCustStartBtn"
                style={{ padding: "0 20px 0 20px" }}
                disabled = {!otp || otp?.length != 4}
              >
                <div className="AddCust">Verify OTP</div>
              </button>
            </div>
        </div>
      </div>
    </div>
  );
}

export default StartTripModal;
