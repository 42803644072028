import React, { useEffect, useRef, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../store/actions/LoginAction";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../Utils/functions";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../Core/Icons";
import { DatePickerField } from "../../Core/DatePicker/input";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { getCities, storeMedia } from "../../store/actions/appActions";
import { driverUpdateDetails } from "../../store/actions/driverAction";
import { Autocomplete } from "@mui/material";
//toast.configure()
function DriverRegister(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [isSendOtp, isSetSendOtp] = useState("0");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");
  const [status, setStatus] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState("");

  const handleSendOtp = () => {
    isSetSendOtp("1");
  };

  const handleVerifyOtp = () => {
    setLoader(true);
    dispatch(
      verifyOtp({ phone, otp }, (res) => {
        console.log("nanrennnn", res);
        setLoader(false);
        if (res?.status == 200 && res?.data?.data) {
          storeDataToAsyncStorage("_token", res.data?.data.token);
          storeDataToAsyncStorage("_user", res.data?.data);

          storeDataToAsyncStorage("_refreshToken", res.data?.data.refreshToken);

          //dispatch(getOwnerAccountDetails(),res => {});
          dispatch(handleLoginSuccess(res.data?.data));
          //dispatch(getOwnerDetails());

          //TODO:
          // console.log("sjeiojroe",state?.pathName || "/truckRecord")
          // navigate(state?.pathName || "/truckRecord");
          //--------------------

          // isSetSendOtp("0");
          // navigate(state?.pathName)
          // toast.success("OTP Send Successfully")
        }
      })
    );
  };

  const datepickerRef = useRef();
  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  const {cities} = useSelector(state => state?.app)
  const [open1, setOpen1] = useState("");
  const [address, setAddress] = useState(-1);


  useEffect(() => {
    if(address != -1){
      let timeout = setTimeout(() => {
        dispatch(getCities({ search: address }));
      }, 750);
      return () => {
        clearTimeout(timeout);
      };  
    }
  }, [address]);

  return (
    <div className="">
      <Header />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 0 0", gap: "10px" }}
      >
        <div className="FontNB24">
          {isSendOtp == "0" ? "Enter Your Details" : "Verify Your Details"}
        </div>
        <div className="FontNS20">Get High Paying Trips</div>

        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            dob: "",
            locations: "",
            dlNo: "",
            addhaarNo: "",
            images : ""
          }}
          onSubmit={(values, actions) => {
            if (isSendOtp == "0") isSetSendOtp("1");
            else{
              if(values?.locations){
                values["locations"] = {
                    latitude : values["locations"]?.lat,
                    longitude : values["locations"]?.long
                }
            }
              dispatch(driverUpdateDetails({
                ...values,userType : 1
              },(resp) => {
                console.log("krokogkrt",resp)
              }))
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              {isSendOtp == "0" ? (
                <FormGroup
                  // style={{
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "space-between",
                  //   marginTop: "30px",
                  // }}
                  style={
                    {
                      // padding: "20px 0 0 40px",
                    }
                  }
                >
                  <div
                    className="d-flex"
                    style={{
                      padding: "0 15px 0 15px",
                      margin: "10px 0 0 0 ",
                      width: "100%",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>Name</div>
                      <input
                        type="text"
                        className="inputBoxAddCust"
                        style={{ width: "100%" }}
                        value={values?.name}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value, true);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{
                      padding: "0 15px 0 15px",
                      gap: "12px",
                      width: "100%",
                      margin: "10px 0 0 0",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>Date of Birth</div>
                      <div style={{ position: "relative", width: "100%" }}>
                        <DatePickerField
                          classname="inputBoxCust1"
                          name="dob"
                          header="dob"
                          value={values.dob}
                          onChange={(v) => setFieldValue("dob", v, true)}
                          maxDate={new Date()}
                        />
                        <label
                          className="date-logo"
                          onClick={() => {
                            handleClickDatepickerIcon();
                          }}
                        >
                          <SvgImage
                            name="CalenderLogo"
                            width="32"
                            height="32"
                            // classname="date-logo"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{
                      padding: "0 15px 0 15px",
                      margin: "10px 0 0 0 ",
                      width: "100%",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>Locations</div>
                      <Autocomplete
                      // freeSolo
                      options={cities}
                      loading={loader}
                      isOptionEqualToValue={(option, value) => {
                        return option?.cityName == value?.cityName;
                      }}
                      getOptionLabel={(item) => {
                        console.log("Tripmkmeromore", item);
                        let reqValue = "";
                        if (item) {
                          reqValue = item?.cityName;
                        }
                        return reqValue;
                      }}
                      onOpen={() => {
                        console.log("moo3");
                        setOpen1(!open1);
                      }}
                      value={values?.from || ""}
                      onInputChange={(event, newValue) => {
                        console.log("Tripjiejroj", event, newValue);

                        setAddress(newValue);

                        if (!newValue) {
                          // setUserId("");
                          setFieldValue("locations", null, true);
                        }
                      }}
                      onChange={(event, newValue) => {
                        setFieldValue("locations", newValue, true);
                      }}
                      sx={{
                        // width: '300px',          // Custom width
                        // backgroundColor: '#f0f0f0', // Background color for the input
                        borderRadius: "8px", // Rounded corners
                        "& .MuiInputBase-root": {
                          padding: "0px",
                          margin: 0, // Custom padding inside the input
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "blue", // Custom color for dropdown arrow
                        },
                        "& .MuiAutocomplete-listbox": {
                          backgroundColor: "#fff", // Background color for dropdown options
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            {...params.inputProps}
                            style={{ width: "100%" }}
                            placeholder="From"
                            // value={value}
                            // onChange={(e) => setFieldValue("from", e.target.value, true)}
                            className="inputBoxAddCust"
                          />
                        </div>
                      )}
                    />
                    </div>
                  </div>

                  <div
                    className="addCustcenter"
                    style={{ margin: "30px 0 0 0" }}
                  >
                    <button
                      className="greenButton Medium text3 AddCustStartBtn"
                      style={{ padding: "0 20px 0 20px" }}
                    >
                      <div className="AddCust">
                        {loader ? (
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        ) : (
                          "Next"
                        )}
                      </div>
                    </button>
                  </div>
                </FormGroup>
              ) : (
                <FormGroup
                  // style={{
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "space-between",
                  //   marginTop: "30px",
                  // }}
                  style={
                    {
                      // padding: "20px 0 0 40px",
                    }
                  }
                >
                  <div
                    className="d-flex"
                    style={{
                      padding: "0 15px 0 15px",
                      margin: "10px 0 0 0 ",
                      width: "100%",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>
                        Driving License Number
                      </div>
                      <input
                        type="text"
                        className="inputBoxAddCust"
                        style={{ width: "100%" }}
                        value={values?.dlNo}
                        onChange={(e) => {
                          setFieldValue("dlNo", e.target.value, true);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{
                      padding: "0 15px 0 15px",
                      margin: "10px 0 0 0 ",
                      width: "100%",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>Aadhaar No</div>
                      <input
                        type="text"
                        className="inputBoxAddCust"
                        style={{ width: "100%" }}
                        value={values?.addhaarNo}
                        onChange={(e) => {
                          setFieldValue("addhaarNo", e.target.value, true);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column"
                    style={{
                      padding: "0 15px 0 15px",
                      margin: "10px 0 0 0 ",
                      width: "100%",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "100%" }}
                    >
                      <div style={{ textAlign: "left" }}>Upload your Photo</div>

                      <div className="d-flex" style={{marginTop : "10px"}}>
                        {status ? (
                          <div className="row">
                            <div
                              className="col-9"
                              style={{ paddingTop: "0.3rem" }}
                            >
                              <p
                                style={{
                                  margin: "0 0 0 10px",
                                  color: "black",
                                }}
                              >
                                {displayName}
                              </p>
                            </div>
                            <div
                              className="col-2"
                              onClick={(e) => {
                                setStatus(false);
                                // e.preventDefault();
                                console.log("dsadsadsadsadsad", values);
                                setFieldValue("receipt", "", true);
                              }}
                              style={{ paddingBottom: "0.5rem" }}
                            >
                              <SvgImage name="CloseButtonLogo" />
                            </div>
                          </div>
                        ) : (
                          <Button
                            className="add-attach-for-fastag-linked-vehicle-new"
                            type="button"
                          >
                            {imageLoader ? (
                              <div style={{ zIndex: 10 }}>
                                <ClipLoader
                                  color={"#485B7C"}
                                  loading={true}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                <SvgImage
                                  name="AddAttachment"
                                  height="25"
                                  width="25"
                                />
                                <input
                                  type="file"
                                  // multiple
                                  // accept="image/*"
                                  value={values.images}
                                  accept="image/*"
                                  className="add-lr-attachment"
                                  onChange={(e) => {
                                    const img = e?.target.files[0];
                                    console.log("image", img?.name);
                                    // e.preventDefault();

                                    if (img == null) {
                                      //clear image
                                      setFieldValue("images", "", true);
                                      //for now  only one supported
                                    } else {
                                      console.log("upload", {
                                        img: img,
                                        uploadType: "images",
                                        isRes: true,
                                      });
                                      setImageLoader(true);
                                      dispatch(
                                        storeMedia(
                                          {
                                            img: img,
                                            uploadType: "images",
                                            isRes: false,
                                          },
                                          (res) => {
                                            setImageLoader(false);
                                            setStatus(true);

                                            // e.preventDefault();
                                            setDisplayName(img?.name);
                                            if (res?.url) { 
                                              console.log("opt", res);
                                              setFieldValue(
                                                "images",
                                                res?.url,
                                                true
                                              );
                                            }
                                          }
                                        )
                                      );
                                      // setFieldValue("amount", values.amount, true);
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </Button>
                        )}
                        {!status ? (
                          <div
                            className="heading3 Regular"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Upload
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="addCustcenter"
                    style={{ margin: "30px 0 0 0" }}
                  >
                    <button
                      className="greenButton Medium text3 AddCustStartBtn"
                      style={{ padding: "0 20px 0 20px" }}
                    >
                      <div className="AddCust">
                        {loader ? (
                          <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        ) : (
                          "Submit"
                        )}
                      </div>
                    </button>
                  </div>

                </FormGroup>
              )}
            </Form>
          )}
        </Formik>
      </div>

      {/* <ToastContainer position="bottom-right" /> */}
    </div>
  );
}
export default DriverRegister;
