import {
TRIP_FORCAST,
GET_VEHICLE_DETAILS,
GET_VEHICLE_CHALLAN,
GET_FASTAG_TXN,
SEND_OTP_FASTAG,
VERIFY_OTP_FASTAG,
GET_ALL_VEHICLES,
TRIP_SEND_OTP,
TRIP_VERIFY_OTP,
TRIP_START,
TRIP_END,
CREATE_ORDER,
} from "../storeTypes";
import {
  getAllVehicleSuccess,
  getFastagTxnSuccess,
  getVehicleChallanSuccess,
  getVehicleDetailsSuccess,
  sendOtpFastagSuccess,
  tripForcast,
  tripForcastSuccess,
  verifyOtpFastagSuccess
} from "../actions/TripsAction";

import {
  createOrderApi,
  getAllVehicleApi,
  getFastagTxnApi,
  getVehicleChallanApi,
  getVehicleDetailsApi,
  sendOtpFastagApi,
  tr,
  tripEndApi,
  tripForcastApi,
  tripSendOtpApi,
  tripStartApi,
  tripVerifyOtpApi,
  verifyOtpApi,
  verifyOtpFastagApi,
} from "../../Utils/Apis";
import { call, put, takeEvery } from "redux-saga/effects";
import { createOrderSuccess, tripEndSuccess, tripSendOtpSuccess, tripStartSuccess, tripVerifyOtpSuccess } from "../actions/driverAction";


function* tripForcastSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(tripForcastApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(tripForcastSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* getVehicleDetailsSaga(action) {
  try {
    console.log("knswreon", action);
    const response = yield call(getVehicleDetailsApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(getVehicleDetailsSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    yield put(getVehicleDetailsSuccess(error?.response));
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* getVehicleChallanSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(getVehicleChallanApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(getVehicleChallanSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    yield put(getFastagTxnSuccess(false)); 
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* getFastagTxnSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(getFastagTxnApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(getFastagTxnSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    yield put(getFastagTxnSuccess(false));
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* sendOtpFastagSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(sendOtpFastagApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(sendOtpFastagSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* verifyOtpFastagSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(verifyOtpFastagApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(verifyOtpFastagSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* getAllVehicleSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(getAllVehicleApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(getAllVehicleSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* tripSendOtpSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(tripSendOtpApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(tripSendOtpSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* tripVerifyOtpSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(tripVerifyOtpApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(tripVerifyOtpSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* tripStartSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(tripStartApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(tripStartSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* tripEndSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(tripEndApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(tripEndSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* createOrderSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(createOrderApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(createOrderSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}



export function* watchTripSagas() {
  yield takeEvery(TRIP_FORCAST, tripForcastSaga);
  yield takeEvery(GET_VEHICLE_DETAILS, getVehicleDetailsSaga);
  yield takeEvery(GET_VEHICLE_CHALLAN, getVehicleChallanSaga);
  yield takeEvery(GET_FASTAG_TXN, getFastagTxnSaga);
  yield takeEvery(SEND_OTP_FASTAG, sendOtpFastagSaga);
  yield takeEvery(VERIFY_OTP_FASTAG, verifyOtpFastagSaga);
  yield takeEvery(GET_ALL_VEHICLES, getAllVehicleSaga);


  yield takeEvery(TRIP_SEND_OTP, tripSendOtpSaga);
  yield takeEvery(TRIP_VERIFY_OTP, tripVerifyOtpSaga);
  yield takeEvery(TRIP_START, tripStartSaga);
  yield takeEvery(TRIP_END, tripEndSaga);
  yield takeEvery(CREATE_ORDER, createOrderSaga);


}
