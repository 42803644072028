import { combineReducers } from "redux";
import appReducer from "./appReducer";
import loginReducer from "./loginReducer";
import TripsReducer from "./TripsReducer";
import driverReducer from "./driverReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    login : loginReducer,
    trip : TripsReducer,
    driver : driverReducer

});