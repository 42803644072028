import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedDriverHoc = (props) => {
  console.log("nkrnineri",sessionStorage.getItem("_driver"))
  let driver = sessionStorage.getItem("_driver") && sessionStorage.getItem("_driver") != "undefined" ? JSON.parse(sessionStorage.getItem("_driver")) : null;
  let token = sessionStorage.getItem("_token") && sessionStorage.getItem("_token") != "undefined" ? JSON.parse(sessionStorage.getItem("_token")) : null;

  if (driver && driver?.phone) {
    if(window.location.pathname == "/" || window.location.pathname == "/driverLogin") {
      return <Navigate to="/driverDashboard"/>
    }
    else return props.component;
  } else if(token){ 
    return props.component;
  }
  else{
    return <Navigate to="/driverLogin" state={{ pathName : props.pathName }}/>;
  }
};

export default ProtectedDriverHoc;
