import {
  TRIP_FORCAST,
  TRIP_FORCAST_SUCCESS,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_CHALLAN,
  GET_VEHICLE_CHALLAN_SUCCESS,
  GET_FASTAG_TXN,
  GET_FASTAG_TXN_SUCCESS,
  SEND_OTP_FASTAG,
  SEND_OTP_FASTAG_SUCCESS,
  VERIFY_OTP_FASTAG,
  VERIFY_OTP_FASTAG_SUCCESS,
  GET_ALL_VEHICLES,
  GET_ALL_VEHICLES_SUCCESS,
  CLEAN_VEHICLE_CHALLAN_SUCCESS,
  CLEAN_FASTAG_TXN,

} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  tripForcast: false,
  vehicleDetails : {},
  vehicleDetailsLoader : false,
  vehicleChallan : {},
  allFastagVehcile : [],
  allFastagVehcileCount : "",
  allFastagListLoader : false,
  fastagTxn : [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRIP_FORCAST: {
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_FORCAST_SUCCESS: {
      return {
        ...state,
        loader: false,
        tripForcast: action.payload,
      };
    }

    case GET_VEHICLE_DETAILS: {
      console.log("sjrfier")
      return {
        ...state,
        vehicleDetailsLoader: true,
        vehicleDetails : {}
      };
    }
    case GET_VEHICLE_DETAILS_SUCCESS: {
      return {
        ...state,
        vehicleDetailsLoader: false,
        vehicleDetails: action.payload?.data,
      };
    }

    case GET_VEHICLE_CHALLAN: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_VEHICLE_CHALLAN_SUCCESS: {
      return {
        ...state,
        loader: false, 
        vehicleChallan:action.payload,
      };
    }
    case CLEAN_VEHICLE_CHALLAN_SUCCESS: {
      return {
        ...state,
        loader: false,
        vehicleChallan:{},
      };
    }



    case GET_FASTAG_TXN: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_FASTAG_TXN_SUCCESS: {
      return {
        ...state,
        loader: false,
        fastagTxn: action.payload,
      };
    }
    
    case CLEAN_FASTAG_TXN: {
      return {
        ...state,
        loader: false,
        fastagTxn: [],
      };
    }


    case SEND_OTP_FASTAG: {
      return {
        ...state,
        loader: true,
      };
    }
    case SEND_OTP_FASTAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        // tripForcast: action.payload,
      };
    }

    case VERIFY_OTP_FASTAG: {
      return {
        ...state,
        loader: true,
      };
    }
    case VERIFY_OTP_FASTAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        allFastagVehcile: action.payload?.data,
      };
    }
    case GET_ALL_VEHICLES: {
      return {
        ...state,
        allFastagListLoader: true,
      };
    }
    case GET_ALL_VEHICLES_SUCCESS: {
      return {
        ...state,
        allFastagListLoader: false,
        allFastagVehcile: action.payload?.data?.rows,
        allFastagVehcileCount : action.payload?.data?.count,
      };
    }

    ////
    default: {
      return state;
    }
  }
};