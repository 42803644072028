import {
  SEARCH_DRIVER,
  SEARCH_DRIVER_SUCCESS,
  SEARCH_DRIVER_HISTORY,
  SEARCH_DRIVER_HISTORY_SUCCESS,
  CLEAN_SEARCH_DRIVER_HISTORY,
  DRIVER_UPDATE_DETAILS,
DRIVER_UPDATE_DETAILS_SUCCESS,
SEND_INTEREST,
SEND_INTEREST_SUCCESS,
DRIVER_GET_JOBS,
DRIVER_GET_JOBS_SUCCESS,
DRIVER_BID_JOBS,
DRIVER_BID_JOBS_SUCCESS,
TRIP_SEND_OTP,
TRIP_SEND_OTP_SUCCESS,
TRIP_VERIFY_OTP,
TRIP_VERIFY_OTP_SUCCESS,
TRIP_START,
TRIP_START_SUCCESS,
TRIP_END,
TRIP_END_SUCCESS,
CREATE_ORDER,
CREATE_ORDER_SUCCESS,
TRIP_PAYMENT_HISTORY,
TRIP_PAYMENT_HISTORY_SUCCESS
} from "../storeTypes";

export const searchDriver = (payload, callBack) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: SEARCH_DRIVER,
    payload,
    callBack,
  };
};

export const searchDriverSuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: SEARCH_DRIVER_SUCCESS,
    payload: data,
  };
};

export const searchDriverHistory = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: SEARCH_DRIVER_HISTORY,
    payload,
  };
};

export const searchDriverHistorySuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: SEARCH_DRIVER_HISTORY_SUCCESS,
    payload: data,
  };
};

export const cleanSearchDriverHistory = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: CLEAN_SEARCH_DRIVER_HISTORY,
  };
};


export const driverUpdateDetails = (data,    callBack) => {
  console.log("toast action success", data);
  return {
    type: DRIVER_UPDATE_DETAILS,
    payload: data,
    callBack
  };
};

export const driverUpdateDetailsSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: DRIVER_UPDATE_DETAILS_SUCCESS,
    payload,
  };
};


export const driverSendInterest = (data,    callBack) => {
  console.log("toast action success", data);
  return {
    type: SEND_INTEREST,
    payload: data,
    callBack
  };
};

export const driverSendInterestSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: SEND_INTEREST_SUCCESS,
    payload,
  };
};


export const driverGetJobs = (data,callBack) => {
  console.log("toast action success", data);
  return {
    type: DRIVER_GET_JOBS,
    payload: data,
    callBack
  };
};

export const driverGetJobsSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: DRIVER_GET_JOBS_SUCCESS,
    payload,
  };
};


export const driverBidJobs = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: DRIVER_BID_JOBS,
    payload: data,
    callBack
  };
};

export const driverBidJobsSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: DRIVER_BID_JOBS_SUCCESS,
    payload,
  };
};

export const tripSendOtp = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: TRIP_SEND_OTP,
    payload: data,
    callBack
  };
};

export const tripSendOtpSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: TRIP_SEND_OTP_SUCCESS,
    payload,
  };
};

export const tripVerifyOtp = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: TRIP_VERIFY_OTP,
    payload: data,
    callBack
  };
};

export const tripVerifyOtpSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: TRIP_VERIFY_OTP_SUCCESS,
    payload,
  };
};

export const tripStart = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: TRIP_START,
    payload: data,
    callBack
  };
};

export const tripStartSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: TRIP_START_SUCCESS,
    payload,
  };
};


export const tripEnd = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: TRIP_END,
    payload: data,
    callBack
  };
};

export const tripEndSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: TRIP_END_SUCCESS,
    payload,
  };
};


export const createOrder = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: CREATE_ORDER,
    payload: data,
    callBack
  };
};

export const createOrderSuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: CREATE_ORDER_SUCCESS,
    payload,
  };
};

export const tripPaymentHistory = (data,callBack) => {
  console.log("toast actionerfer success", data);
  return {
    type: TRIP_PAYMENT_HISTORY,
    payload: data,
    callBack
  };
};

export const tripPaymentHistorySuccess = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: TRIP_PAYMENT_HISTORY_SUCCESS,
    payload,
  };
};



