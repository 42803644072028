import React, { useEffect, useRef, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Slider,
  styled,
  TextField,
} from "@mui/material";
import SvgImage from "../../Core/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getCities } from "../../store/actions/appActions";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { tripForcast } from "../../store/actions/TripsAction";
import { ClipLoader } from "react-spinners";
import { DatePickerField } from "../../Core/DatePicker/input";
import { searchDriver } from "../../store/actions/driverAction";
import { duration, isDate } from "moment/moment";
import { useNavigate } from "react-router-dom";
//toast.configure()



function SearchDrivers(props) {
  const [sendOtp, setSendOtp] = useState(0);
  const navigate = useNavigate()

  const dispatch = useDispatch();
  const [address, setAddress] = useState(-1);

  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");

  const datepickerRef = useRef();
  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };
  useEffect(() => {
    console.log("ajiejiaekmrkew");
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("msoejfojwe", position);
        dispatch(
          getCities({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          })
        );
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    if(address != -1){
      let timeout = setTimeout(() => {
        dispatch(getCities({ search: address }));
      }, 750);
      return () => {
        clearTimeout(timeout);
      };  
    }
  }, [address]);
//   const [loader,setLoader] = useState("");
  const {cities,loader} = useSelector(state => state?.app)
  console.log("nknise",cities)
  const [driverSalaryAmt,setDriverSalaryAmt] = useState("");
  const [mileageAmt,setMileageAmt] = useState("");
  const [totalDistance,setTotalDistance] = useState("");
  const [fastagTotal,setFastagTotal] = useState("");
  const [others,setOthers] = useState({loading : 0,comission : 0,parking : 0,repairs : 0,other : 0,total : 0});

  const loader1 = useSelector(state => state?.driver?.loader)

  return (
    <div className="">
      <Header />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 0 0", gap: "10px" }}
      >
        <div className="FontNB24">Search Drivers</div>
        <div className="FontNS20">Get Verified and Experienced Drivers</div>

        <Formik
          enableReinitialize
          initialValues={{
            from: "",
            to: "",
            date: "",
            days: "3",
            amount: "5",
          }}
          onSubmit={(values, actions) => {
            let data = {
                "from": values?.from?.cityName,
                "to": values?.to?.cityName,
                // "vrn": "any",
                "bidAmount": values?.amount,
                "latitude": values?.from?.lat,
                "longitude": values?.from?.long,
                date : values?.date,
                days : values?.days,
            };
            // setLoader(true)
            dispatch(
              searchDriver(data, (resp) => {
                console.log("sjefoejo", resp);
                // setLoader(false)
                if (resp?.status == 200) {
                    navigate(`/driverSearchList/${resp?.data?.data?.search?.searchId}`)
                }
              })
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   marginTop: "30px",
                // }}
                style={
                  {
                    // padding: "20px 0 0 40px",
                  }
                }
              >
                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>From</div>

                    <Autocomplete
                      // freeSolo
                      options={cities}
                      loading={loader}
                      isOptionEqualToValue={(option, value) => {
                        return option?.cityName == value?.cityName;
                      }}
                      getOptionLabel={(item) => {
                        console.log("Tripmkmeromore", item);
                        let reqValue = "";
                        if (item) {
                          reqValue = item?.cityName;
                        }
                        return reqValue;
                      }}
                      onOpen={() => {
                        console.log("moo3");
                        setOpen1(!open1);
                      }}
                      value={values?.from || ""}
                      onInputChange={(event, newValue) => {
                        console.log("Tripjiejroj", event, newValue);

                        setAddress(newValue);

                        if (!newValue) {
                          // setUserId("");
                          setFieldValue("from", null, true);
                        }
                      }}
                      onChange={(event, newValue) => {
                        setFieldValue("from", newValue, true);
                      }}
                      sx={{
                        // width: '300px',          // Custom width
                        // backgroundColor: '#f0f0f0', // Background color for the input
                        borderRadius: "8px", // Rounded corners
                        "& .MuiInputBase-root": {
                          padding: "0px",
                          margin: 0, // Custom padding inside the input
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "blue", // Custom color for dropdown arrow
                        },
                        "& .MuiAutocomplete-listbox": {
                          backgroundColor: "#fff", // Background color for dropdown options
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            {...params.inputProps}
                            style={{ width: "100%" }}
                            placeholder="From"
                            // value={value}
                            // onChange={(e) => setFieldValue("from", e.target.value, true)}
                            className="inputBoxAddCust"
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="d-flex flex-column" style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>To</div>
                    <Autocomplete
                      freeSolo
                      options={cities}
                      // loading={loader}
                      isOptionEqualToValue={(option, value) => {
                        return option?.cityName === value?.cityName;
                      }}
                      getOptionLabel={(item) => {
                        console.log("Tripmkmeromore", item);
                        let reqValue = "";
                        if (item) {
                          reqValue = item?.cityName;
                        }
                        return reqValue;
                      }}
                      onOpen={() => {
                        console.log("moo3");
                        setOpen1(!open1);
                      }}

                      onClick={() => setAddress("")}
                      value={values?.to || ""}
                      onInputChange={(event, newValue) => {
                        console.log("Tripjiejroj", event, newValue);

                        setAddress(newValue);

                        if (!newValue) {
                          // setUserId("");
                          setFieldValue("to", null, true);
                        }
                      }}
                      onChange={(event, newValue) => {
                        // console.log("jdiofjioejr",{User : {fullName: newValue?.User?.fullName,phone : newValue?.User?.phone,userId : newValue?.userId}})
                        setFieldValue("to", newValue, true);
                        // setFieldValue("driverName", newValue, true);
                      }}
                      sx={{
                        // width: '300px',          // Custom width
                        // backgroundColor: '#f0f0f0', // Background color for the input
                        borderRadius: "8px", // Rounded corners
                        "& .MuiInputBase-root": {
                          padding: "0px",
                          margin: 0, // Custom padding inside the input
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "blue", // Custom color for dropdown arrow
                        },
                        "& .MuiAutocomplete-listbox": {
                          backgroundColor: "#fff", // Background color for dropdown options
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            {...params.inputProps}
                            style={{ width: "100%" }}
                            placeholder="To"
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            className="inputBoxAddCust"
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    gap: "12px",
                    width: "100%",
                    margin: "10px 0 0 0"
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>Date</div>
                    <div style={{position : "relative",width : "100%"}}>

                    <DatePickerField
                              classname="inputBoxCust1"
                              name="date"
                              header="date"
                              value={values.date}
                              onChange={(v) => setFieldValue("date", v, true)}
                              minDate={new Date()}
                            />
                    <label
                      className="date-logo"
                      onClick={() => {
                        handleClickDatepickerIcon();
                      }}
                    >
                      <SvgImage
                        name="CalenderLogo"
                        width="32"
                        height="32"
                        // classname="date-logo"
                      />
                    </label>
                    </div>
                  </div>
                  <div className="d-flex flex-column" style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>Duration (in Days)</div>

                    <div >
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder="From"
                            value={values?.days}
                            onChange={(e) => setFieldValue("days", e.target.value, true)}
                            className="inputBoxAddCust"
                          />
                        </div>
                  </div>
                </div>


                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    margin: "10px 0 0 0 ",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>How much you want to pay to Driver?</div>
                    <input
                      type="text"
                      className="inputBoxAddCust"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFieldValue("amount", e.target.value, true);
                      }}
                    />
                  </div>
                </div>
                <div className="addCustcenter" style={{ margin: "30px 0 0 0" }}>
                  <button
                    className="greenButton Medium text3 AddCustStartBtn"
                    style={{ padding: "0 20px 0 20px" }}
                  >
                    <div className="AddCust">{loader1 ?                        <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Get Drivers"}</div>
                  </button>
                </div>

              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
export default SearchDrivers;
