import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch } from "react-redux";
import {
  handleLoginSuccess,
  sendOtp,
  verifyOtp,
} from "../../../../store/actions/LoginAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { storeMedia } from "../../../../store/actions/appActions";
import DriverHeader from "../../../../SubComponents/DriverHeader";
import BidModal from "../../../../SubComponents/BidModal";
import StartTripModal from "../../../../SubComponents/StartTripModal";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../../../Core/Icons";
import { tripStart } from "../../../../store/actions/driverAction";
//toast.configure()
function VerifyTrip(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");
  const dispatch = useDispatch();
  const [vrn, setVrn] = useState();
  //Data format = { name: "", img: "",signedUrl: "" }
  const [imgData, setImgData] = useState([
    { name: "Vehicle Front", loader: false, data: [] },
    { name: "Vehicle Back", loader: false, data: [] },
    { name: "Vehicle Left Side", loader: false, data: [] },
    {
      name: "Vehicle Right Side",
      loader: false,
      data: [],
    },
    { name: "Odometer", loader: false, data: [] },
    { name: "Fuel Tank Photo", loader: false, data: [] },
    {
      name: "Selfie with Vehicle",
      loader: false,
      data: [],
    },
  ]);
  const datepickerRef = useRef();

  const { id } = useParams();

  const [openBidModal, setOpenBidModal] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const startTrip = () => {
    setLoader1(true);
    const verifyTripData = imgData?.filter((r) => r?.data?.length > 0);
    dispatch(
      tripStart({ searchId: id, data: verifyTripData, vrn }, (resp) => {
        console.log("slemlfkmerl", resp);
        setLoader1(false);
        if (resp?.status == 200 && resp?.data?.message == "trip started.") {
          navigate("/driverDashboard");
        }
      })
    );
  };

  return (
    <div className="">
      <DriverHeader />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 40px 0", gap: "10px", padding: "0 7% 0 7%" }}
      >
        <div style={{ textAlign: "left" }} className="fontNB24">
          Verify your Trip
        </div>

        <div
          className="d-flex"
          style={{
            margin: "10px 0 0 0 ",
            width: "100%",
          }}
        >
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <div style={{ textAlign: "left" }}>Vehicle Number</div>
            <input
              type="text"
              className="inputBoxAddCust"
              style={{ width: "100%" }}
              onChange={(e) => {
                setVrn(e.target.value);
              }}
            />
          </div>
        </div>

        {imgData?.map((item, index) => {
          return (
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="d-flex flex-column">
                <div className="fontNS16">{item?.name}</div>
                <div className="d-flex" style={{ marginTop: "10px" }}>
                  <Button
                    className="add-attach-for-fastag-linked-vehicle-new"
                    type="button"
                  >
                    {item?.loader ? (
                      <div style={{ zIndex: 10 }}>
                        <ClipLoader
                          color={"#485B7C"}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          cursor: "pointer",
                        }}
                      >
                        <SvgImage name="AddAttachment" height="25" width="25" />
                        <input
                          type="file"
                          // multiple
                          // accept="image/*"
                          //   value={values.receipt}
                          accept="image/*"
                          className="add-lr-attachment"
                          onChange={(e) => {
                            const img = e?.target.files[0];
                            console.log("image", img?.name);
                            // e.preventDefault();

                            if (img == null) {
                              //clear image
                              //   setFieldValue("receipt", "", true);
                              //for now  only one supported
                            } else {
                              console.log("upload", {
                                img: img,
                                uploadType: "receipt",
                                isRes: true,
                              });
                              let tmp = [...imgData];
                              tmp[index]["loader"] = true;
                              setImgData(tmp);
                              dispatch(
                                storeMedia(
                                  {
                                    img: img,
                                    uploadType: "receipt",
                                    isRes: false,
                                  },
                                  (res) => {
                                    let tmp = [...imgData];
                                    tmp[index]["loader"] = false;
                                    setImgData(tmp);

                                    // e.preventDefault();
                                    if (res?.url) {
                                      console.log("opt", res);
                                      let tmp = [...imgData];
                                      let content = tmp[index].data;
                                      let text = {
                                        name: img?.name,
                                        img: res?.url,
                                        signedUrl: res?.signedUrl,
                                      };
                                      content.push(text);
                                      tmp[index].data = content;
                                      console.log("kjojoj", tmp[index].data);
                                      setImgData(tmp);
                                    }
                                  }
                                )
                              );
                              // setFieldValue("amount", values.amount, true);
                            }
                          }}
                        />
                      </div>
                    )}
                  </Button>
                </div>
              </div>
              {console.log("kjojoj", item?.data)}

              <div className="d-flex" style={{ gap: "10px" }}>
                {item?.data?.map((d, i) => {
                  return (
                    <div style={{ width: "50px", position: "relative" }}>
                      <img src={d?.signedUrl} style={{ width: "100%" }} />
                      <div>
                        <div
                          className="closeBtnWithBackground"
                          style={{ background: "white", border: "none" }}
                          onClick={() => {
                            let data1 = imgData?.map((item) => ({ ...item }));
                            let newData = data1[index]?.data?.filter(
                              (id, idx) => idx != i
                            );
                            console.log("djkrfjkoer", newData);
                            imgData[index].data = newData?.map((idxx) => ({
                              ...idxx,
                            }));
                            console.log("mmeomrof", imgData);
                            setImgData([...imgData]);
                          }}
                        >
                          <SvgImage
                            classname="close-btn-text"
                            style={{ width: "10%", height: "10%" }}
                            name="crossBtnWIthBackground"
                            width="20"
                            height="20"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div
          className="addCustcenter"
          style={{ margin: "30px 0 0 0" }}
          onClick={() => {
            startTrip();
          }}
        >
          <button
            className="greenButton Medium text3 AddCustStartBtn"
            style={{ padding: "0 20px 0 20px" }}
            disabled={loader1}
          >
            <div className="AddCust">{loader1 ? <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Start Trip"}</div>
          </button>
        </div>
      </div>
    </div>
  );
}
export default VerifyTrip;
