import { TOAST_STATUS, TOAST_STATUS_SUCCESS,
  GET_CITIES,
  GET_CITIES_SUCCESS,  STORE_MEDIA_FILE,
  STORE_MEDIA_FILE_SUCCESS,  GET_SIGNED_URL,
  GET_SIGNED_URL_SUCCESS, } from "../storeTypes";

export const toastStatus = (payload) => {
    console.log("toastNNJNJNaction", payload);
    return {
      type: TOAST_STATUS,
      payload,
    };
  };
  
  export const toastStatusSuccess = (data) => {
    console.log("toast action success", data);
    return {
      type: TOAST_STATUS_SUCCESS,
      payload: data,
    };
  };


export const getCities = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: GET_CITIES,
    payload,
  };
};

export const getCitiesSuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: GET_CITIES_SUCCESS,
    payload: data,
  };
};

export const storeMedia = (payload, callBack) => {
  return {
    type: STORE_MEDIA_FILE,
    payload,
    callBack,
  };
};
export const storeMediaSuccess = () => {
  return {
    type: STORE_MEDIA_FILE_SUCCESS,
  };
};

export const getSignedUrlApiAction = (data, callBack) => {
  return {
    type: GET_SIGNED_URL,
    payload: data,
    callBack,
  };
};

export const getSignedUrlApiActionSuccess = (data) => {
  return {
    type: GET_SIGNED_URL_SUCCESS,
    payload: data,
  };
};