
export function processErrorAndRespond(error) {
  let statusCode = 0;
  let message = "Server Error";
  console.log(error);
  if (error && error.response) {
    statusCode = error.response.status;
    switch (error.response.status) {
      case 401:
        message = "You are not authorized to perform this action";
        break;
      case 404:
        message = "The requested request is unknown to server";
        break;
      default:
        break;
    }
  }
  return [statusCode, message];
}
export const storeDataToAsyncStorage = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    sessionStorage.setItem(key, jsonValue);
    // await AsyncStorage.setItem(key, jsonValue);
    console.log("storeDataToAsyncStorage", jsonValue);
  } catch (e) {
    console.log("storeDataToAsyncStorage error", e);
  }
};

export const getDataFromAsyncStorage = async (key) => {
  try {
    // const jsonValue = await AsyncStorage.getItem(key);
    const jsonValue = sessionStorage.getItem(key);
    console.log("get token", jsonValue);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log("getDataFromAsyncStorage error", e);
  }
};

export const removeItemFromAsyncStorage = async (key) => {
  try {
    // await AsyncStorage.setItem(key);
    sessionStorage.removeItem(key);
  } catch (e) {
    console.log("removeItemFromAsyncStorage error", e);
  }
};

export const capitalizeFirstLetter = (val) => {
  return val?.charAt(0)?.toUpperCase() + val?.slice(1);
};
export const getInitials = (string) => {
  let names = string?.split(" "),
    initials =
      names?.[0]?.substring(0, 1)?.toUpperCase() || names?.toUpperCase();

  if (names?.length > 1) {
    initials += names[names?.length - 1]?.substring(0, 1)?.toUpperCase();
  }
  return initials || string;
};

const colors = [
  "#DEFFEA",
  "#FFF0DB",
  "#FFE5EF",
  "#F0E9FF",
  "#E0FAFF",
  "#FFE9F3",
];
export const getRandomeColor = (seqNo) => {
  if (seqNo < colors.length) {
    return colors[seqNo];
  } else {
    return colors[seqNo % colors.length];
  }
};

export const numberToInr = (amount) => {
  if (amount && !isNaN(amount)) {
    var str = amount.toString();
    var x = str.split(".");
    var x1 = Math.abs(parseFloat(x[0]));
    var x2 = x.length > 1 ? "." + x[1].substr(0, 2) : ".00";
    x2 = x2.length == 2 ? x2 + "0" : x2;
    var rgx = /(\d+)(\d{3})/;
    var z = 0;
    var len = String(x1).length;
    var num = parseInt(len / 2 - 1);
    while (rgx.test(x1)) {
      if (z > 0) {
        x1 = String(x1).replace(rgx, "$1" + "," + "$2");
      } else {
        x1 = String(x1).replace(rgx, "$1" + "," + "$2");
        rgx = /(\d+)(\d{2})/;
      }
      z++;
      num--;
      if (num == 0) {
        break;
      }
    }
    if (x[0] >= 0) {
      return "₹" + x1 + x2;
    } else {
      return "-₹" + x1 + x2;
    }
  }
  return "₹0.00";
};

export const prepareForSubmitURL = (urls) => {
  if (urls?.length > 0) {
    const cleanUrls = urls.map((itm) => itm?.split("?")?.[0]);
    return cleanUrls;
  }
  return [];
};

export function msToTime(duration) {
  console.log("asdfghjsdfgh", duration);
  if (duration < 0) {
    return "Trip Not Started";
  }
  var minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor(duration / (1000 * 60 * 60));

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes;
}
