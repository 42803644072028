import {  TRIP_FORCAST,
  TRIP_FORCAST_SUCCESS,
  GET_VEHICLE_DETAILS,
GET_VEHICLE_DETAILS_SUCCESS,
GET_VEHICLE_CHALLAN,
GET_VEHICLE_CHALLAN_SUCCESS,
GET_FASTAG_TXN,
GET_FASTAG_TXN_SUCCESS,
SEND_OTP_FASTAG,
SEND_OTP_FASTAG_SUCCESS,
VERIFY_OTP_FASTAG,
VERIFY_OTP_FASTAG_SUCCESS,
GET_ALL_VEHICLES,
GET_ALL_VEHICLES_SUCCESS,
CLEAN_VEHICLE_CHALLAN_SUCCESS,
CLEAN_FASTAG_TXN
} from "../storeTypes";



export function tripForcast(data,callBack) {
  return {
    type: TRIP_FORCAST,
    payload: data,
    callBack
  };
}

export function tripForcastSuccess(data) {
  return {
    type: TRIP_FORCAST_SUCCESS,
    payload: data,
  };
}

export function getVehicleDetails(data,callBack) {
  console.log("sjrfier")
  return {
    type: GET_VEHICLE_DETAILS,
    payload: data,
    callBack
  };
}

export function getVehicleDetailsSuccess(data) {
  return {
    type: GET_VEHICLE_DETAILS_SUCCESS,
    payload: data,
  };
}

export function getVehicleChallan(data,callBack) {
  return {
    type: GET_VEHICLE_CHALLAN,
    payload: data,
    callBack
  };
}

export function getVehicleChallanSuccess(data) {
  return {
    type: GET_VEHICLE_CHALLAN_SUCCESS,
    payload: data,
  };
}

export function cleanVehicleChallan() {
  return {
    type: CLEAN_VEHICLE_CHALLAN_SUCCESS,
  };
}

export function getFastagTxn(data,callBack) {
  return {
    type: GET_FASTAG_TXN,
    payload: data,
    callBack
  };
}

export function getFastagTxnSuccess(data) {
  return {
    type: GET_FASTAG_TXN_SUCCESS,
    payload: data,
  };
}

export function cleanFastagTxn(data) {
  return {
    type: CLEAN_FASTAG_TXN,
    payload: data,
  };
}


export function sendOtpFastag(data,callBack) {
  return {
    type: SEND_OTP_FASTAG,
    payload: data,
    callBack
  };
}

export function sendOtpFastagSuccess(data) {
  return {
    type: SEND_OTP_FASTAG_SUCCESS,
    payload: data,
  };
}

export function verifyOtpFastag(data,callBack) {
  return {
    type: VERIFY_OTP_FASTAG,
    payload: data,
    callBack
  };
}

export function verifyOtpFastagSuccess(data) {
  return {
    type: VERIFY_OTP_FASTAG_SUCCESS,
    payload: data,
  };
}

export function getAllVehicle(data,callBack) {
  return {
    type: GET_ALL_VEHICLES,
    payload: data,
    callBack
  };
}

export function getAllVehicleSuccess(data) {
  return {
    type: GET_ALL_VEHICLES_SUCCESS,
    payload: data,
  };
}