import React, { useEffect, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleChallan } from "../../store/actions/TripsAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SvgImage from "../../Core/Icons";
import { ClipLoader } from "react-spinners";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from '@mui/icons-material/StarHalf';
import {
  driverSendInterest,
  searchDriver,
} from "../../store/actions/driverAction";
import { motion } from "framer-motion";

function DriverSearchList(props) {
  const { vrn } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [driverIds, setDriverIds] = useState([]);
  const navigate = useNavigate();

  const { driverSearchList } = useSelector((state) => state?.driver);
  console.log("jsrfjr", driverSearchList);

  const [searchData,setSearchData] = useState(null)

  useEffect(() => {
    console.log("sjrofojrs");
    setDriverIds([]);
    if (id)
      dispatch(
        searchDriver(
          {
            searchId: id,
          },
          (resp) => {
            console.log("sjefoejo", resp);
            if(resp?.status == 200){
              setSearchData(resp?.data?.data?.search)
            }
            
            // setLoader(false)
          }
        )
      );
  }, [id]);

  //   let driverSearchList = [
  //     {
  //       name: "12",
  //       dlNo: "KA36 20120013331",
  //       vehicleClass: [
  //         {
  //           cat: "NT",
  //           date: "2012-12-11",
  //           class: "LMV",
  //         },
  //       ],
  //       psvBadges: null,
  //       image: "21d3e1c9-cb86-42fc-b2de-060c1c254ab3-image.jpeg",
  //       validityDetails: [
  //         {
  //           type: "Non Transport",
  //           issueDate: "2012-12-11",
  //           validTill: "2032-12-10",
  //         },
  //         {
  //           type: "Transport",
  //           issueDate: null,
  //           validTill: null,
  //         },
  //         {
  //           type: "Hazardous",
  //           issueDate: null,
  //           validTill: null,
  //         },
  //         {
  //           type: "Hill",
  //           issueDate: null,
  //           validTill: null,
  //         },
  //       ],
  //       latitude: 12.9543,
  //       longitude: 77.4661,
  //       distance: 9.468290435712882,
  //       driverBid: null,
  //     },
  //     {
  //       name: "13",
  //       dlNo: "dl333",
  //       vehicleClass: null,
  //       psvBadges: null,
  //       image: null,
  //       validityDetails: null,
  //       latitude: 12.9543,
  //       longitude: 77.4661,
  //       distance: 9.468290435712882,
  //       driverBid: null,
  //     },
  //     {
  //       name: "11",
  //       dlNo: "RJ01 19970116557",
  //       vehicleClass: [
  //         {
  //           cat: "NT",
  //           date: "1997-12-12",
  //           class: "LMV",
  //         },
  //         {
  //           cat: "TR",
  //           date: "2000-01-23",
  //           class: "TRANS",
  //         },
  //       ],
  //       psvBadges: [
  //         {
  //           date: null,
  //           class: "TRANS",
  //         },
  //       ],
  //       image: "efc33e99-1251-4ac6-a156-d8be9b5898fe-image.jpeg",
  //       validityDetails: [
  //         {
  //           type: "Non Transport",
  //           issueDate: "2019-03-14",
  //           validTill: "2028-05-02",
  //         },
  //         {
  //           type: "Transport",
  //           issueDate: "2022-03-11",
  //           validTill: "2027-03-10",
  //         },
  //         {
  //           type: "Hazardous",
  //           issueDate: null,
  //           validTill: null,
  //         },
  //         {
  //           type: "Hill",
  //           issueDate: null,
  //           validTill: null,
  //         },
  //       ],
  //       latitude: 12.9543,
  //       longitude: 77.4661,
  //       distance: 9.468405115337577,
  //       driverBid: null,
  //     },
  //   ];

  // vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data

  // Disposed_data

  const cardVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 10,
      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
    
  };
  return (
    <div className="">
      <Header />
      {/* 
      {driverSearchList?.data == false ? <div>OOPS... SERVER DOWN</div> : !driverSearchList?.data?.response ? (
        <div>LOADING....</div>
      ) : ( */}
      <>
        {" "}
        <div
          className="d-flex flex-column"
          style={{ margin: "10px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">{state?.vrn}</div>
          <div className="FontNB24">
            {driverSearchList?.length || 0} Drivers Found!
          </div>
        </div>
        <div className="fontNS16" style={{ color: "rgba(108, 108, 108, 1)" }}>
          Your Request is sent to Drivers
        </div>
        <div className="fontNS16" style={{ color: "rgba(108, 108, 108, 1)" }}>
          We will let you know once they accept it
        </div>
        <div className="fontNS16" style={{ color: "rgba(108, 108, 108, 1)" }}>
          or
        </div>
        <div className="fontNS16" style={{ color: "rgba(108, 108, 108, 1)" }}>
          Pick Them here for us to connect
        </div>
        {driverSearchList?.length > 0 && (
          <div
            className="addCustcenter"
            style={{
              textAlign: "right",
              margin: "10px 10px 0 0",
              cursor: "pointer",
            }}
            onClick={() => {
              let drivers = driverSearchList?.map((it) => {
                if (it?.status == null) return it?.driverId;
                else return -1;
              });
              drivers = drivers?.filter((item) => item != -1);
              if (drivers?.length > 0)
                dispatch(
                  driverSendInterest(
                    {
                      searchId: id,
                      drivers: drivers,
                    },
                    (res) => {
                      if (res?.data?.type == "success") {
                        dispatch(
                          searchDriver(
                            {
                              searchId: id,
                            },
                            (resp) => {
                              console.log("sjefoejo", resp);
                              // setLoader(false)
                            }
                          )
                        );
                      }
                    }
                  )
                );
            }}
          >
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={toggleCreateCust}
            >
              <div className="AddCust">
                {false ? (
                  <ClipLoader
                    color={"#2E7C7B"}
                    loading={true}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Notify All Drivers"
                )}
              </div>
            </button>
          </div>
        )}
        <div
          className="d-flex flex-column"
          style={{ gap: "60px", padding: "10px 0 20px 0" }}
        >
          <div
            className="d-flex flex-column"
            style={{ padding: "20px", gap: "10px" }}
          >
            {driverSearchList &&
              driverSearchList?.map((item, idx) => {
                let rateee = (Math.random() * 2 + 3).toFixed(1)
                let fullStars = Math.floor(rateee);
                const hasHalfStar = rateee - fullStars >= 0.5;
                let rateArr = [];
                for (let index = 0; index < fullStars; index++) {
                  rateArr.push("a");
                }

                return (
                  <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}>
                   <motion.div  variants={cardVariants} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }} className="d-flex flex-column box-driver-search" >
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        gap: "20px",
                        border: "1px solid rgba(108, 108, 108, 0.24)",
                        borderBottom: "none",
                      }}
                    >
                      <div
                        style={{ width: "30%", padding: "15px" }}
                        className="d-flex flex-column"
                      >
                        <div>
                          {item?.image ? (
                            <img
                              src={`https://storage.googleapis.com/logipe-assets/${item?.image}`}
                              style={{ width: "80px" }}
                            />
                          ) : (
                            <img
                              src={`/assets/images/image.png`}
                              style={{ width: "80px" }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{ width: "70%", gap: "15px", padding: "10px" }}
                        className="d-flex flex-column"
                      >
                        <div
                          className="d-flex"
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="d-flex flex-column"
                            style={{ textAlign: "left" }}
                          >
                            <div
                              className="fontNS16"
                              style={{ color: "#000000" }}
                            >
                              {item?.name || ""}
                            </div>
                            {item?.distance?.toFixed(2)<1?(<div className="fontNM12">
                              Driver is nearby.
                            </div>):(<div className="fontNM12">
                              Distance : {item?.distance?.toFixed(2) || ""} KM away
                            </div>)}
                            <div className="fontNM12">
                              Driver Quotation :{item?.driverBid || ""}
                            </div>
                            {item?.phone &&                             <div className="fontNM12">
                              Driver Phone : {item?.phone || ""}
                            </div>}
                          </div>
                          <div style={{}} className="d-flex">
                            {rateArr?.map((item) => {
                              return <StarIcon style={{ color: "#FFB800" }} />;
                            })}
                            {
                              hasHalfStar && <StarHalfIcon style={{ color: "#FFB800" }} />
                            }
                          </div>
                        </div>
                        {!item?.status && item?.status != "0"  && !item?.driverBid ? (
                          <div
                            className="request-btn-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // let dr = driverIds;
                              // dr.push(item?.driverId)
                              // setDriverIds(dr)

                              dispatch(
                                driverSendInterest(
                                  {
                                    searchId: id,
                                    drivers: [item?.driverId],
                                  },
                                  (res) => {
                                    if (res?.data?.type == "success") {
                                      dispatch(
                                        searchDriver(
                                          {
                                            searchId: id,
                                          },
                                          (resp) => {
                                            console.log("sjefoejo", resp);
                                            // setLoader(false)
                                          }
                                        )
                                      );
                                    }
                                  }
                                )
                              );
                            }}
                          >
                            Send Request
                          </div>
                        ) : ((item?.status == "0" || item?.status == null) && item?.driverBid >0) ? 
                          <div className="request-btn-1" style={{ cursor: "pointer" }} onClick={() => {
                            navigate(`/PayDriver/${id}`,{state : {details : item,searchData}})

                          }}>
                          Book Now
                        </div> : item?.status == 1 ? <div className="request-btn-2">
                          
                            Details shared
                          </div> : item?.status == 2 ? <div className="request-btn-2">
                          
                          Driver Verified
                        </div> : item?.status == 3 ? <div className="request-btn-2">
                          
                         Trip Started
                        </div> : (
                          <div className="request-btn">
                            Request Not Accepted.
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
                    >
                      <div className="d-flex">
                        <div
                          className="d-flex"
                          style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                        >
                          {true || item?.psvBadges?.length > 0 ? (
                            <>
                              {" "}
                              <div>
                                <SvgImage name="tick" />
                              </div>
                              <div className="fontNM12 text-align-center-text">
                                DL Verified
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                           {true || item?.psvBadges?.length > 0 ? (
                            <>
                              {" "}
                              <div>
                                <SvgImage name="tick" />
                              </div>
                              <div className="fontNM12 text-align-center-text">
                                Aadhaar Verified
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* <div
                        className="d-flex"
                        style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                      >
                        <div>
                          <SvgImage name="tick" />
                        </div>
                        <div className="fontNM12 text-align-center-text">DL Verified</div>
                      </div>
                      <div
                        className="d-flex"
                        style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                      >
                        <div>
                          <SvgImage name="tick" />
                        </div>
                        <div className="fontNM12 text-align-center-text">DL Verified</div>
                      </div> */}
                      </div>
                    </div>
                  </motion.div>
                  </motion.div>

                );
              })}
          </div>

          {
            <div
              className="addCustcenter"
              style={{ margin: "10px 10px 0 0" }}
              onClick={() => navigate("/driverSearchHistory")}
            >
              <button
                className="greenButton Medium text3 AddCustStartBtn"
                style={{ padding: "0 20px 0 20px" }}
                // onClick={toggleCreateCust}
              >
                <div className="AddCust">
                  {false ? (
                    <ClipLoader
                      color={"#2E7C7B"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "Check All Requests"
                  )}
                </div>
              </button>
            </div>
          }
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export default DriverSearchList;
