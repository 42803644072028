import {SEARCH_DRIVER,SEARCH_DRIVER_SUCCESS,  SEARCH_DRIVER_HISTORY_SUCCESS,
  SEARCH_DRIVER_HISTORY,
  CLEAN_SEARCH_DRIVER_HISTORY,  DRIVER_UPDATE_DETAILS,
  DRIVER_UPDATE_DETAILS_SUCCESS,
  SEND_INTEREST,
  SEND_INTEREST_SUCCESS,
  DRIVER_GET_JOBS,
  DRIVER_GET_JOBS_SUCCESS,
  DRIVER_BID_JOBS,
  DRIVER_BID_JOBS_SUCCESS,
  TRIP_SEND_OTP,
  TRIP_SEND_OTP_SUCCESS,
  TRIP_VERIFY_OTP,
  TRIP_VERIFY_OTP_SUCCESS,
  TRIP_START,
  TRIP_START_SUCCESS,
  TRIP_END,
  TRIP_END_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  TRIP_PAYMENT_HISTORY,
TRIP_PAYMENT_HISTORY_SUCCESS
 } from "../storeTypes";


const INIT_STATE = {
  loader: false,  
  driverSearchList: [],
  driverSearchHistoryList : [],
  driverSearchHistoryListCount : 0,
  driverInterestedJobs : [],
  driverNotInterestedJobs : [],
  driverJobsLoader : false,
  tripPaymentHistoryList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SEARCH_DRIVER: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case SEARCH_DRIVER_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        driverSearchList:action?.payload?.data?.drivers || []
      };
    }

    case SEARCH_DRIVER_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case SEARCH_DRIVER_HISTORY_SUCCESS: {
      return {
        ...state,
        loader: false,
        driverSearchHistoryList : action?.payload?.data?.rows,
        driverSearchHistoryListCount : action?.payload?.data?.count,
      };
    }


    case CLEAN_SEARCH_DRIVER_HISTORY: {
      return {
        ...state,
        driverSearchHistoryList: [],
        driverSearchHistoryListCount : 0
      };
    }

    case DRIVER_UPDATE_DETAILS: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_UPDATE_DETAILS_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        driverSearchList:action?.payload?.data
      };
    }


    case SEND_INTEREST: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case SEND_INTEREST_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        // driverSearchList:action?.payload?.data
      };
    }


    case DRIVER_GET_JOBS: {
      console.log("toast reducer");
      return {
        ...state,
        // loader: true,
        driverJobsLoader : true
      };
    }
    case DRIVER_GET_JOBS_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        driverInterestedJobs:action?.payload?.data?.intersted ? action?.payload?.data?.intersted : action?.payload?.data,
        driverNotInterestedJobs:action?.payload?.data?.jobs ? action?.payload?.data?.jobs : action?.payload?.data,
        driverJobsLoader : false
      };
    }


    case DRIVER_BID_JOBS: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case DRIVER_BID_JOBS_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        driverSearchList:action?.payload?.data
      };
    }


    case TRIP_SEND_OTP: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_SEND_OTP_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
      };
    }


    case TRIP_VERIFY_OTP: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_VERIFY_OTP_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
      };
    }


    case TRIP_START: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_START_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
      };
    }

    case TRIP_END: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_END_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
      };
    }

    case CREATE_ORDER: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
      };
    }

    case TRIP_PAYMENT_HISTORY: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TRIP_PAYMENT_HISTORY_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        tripPaymentHistoryList:action.payload?.data?.rows
      };
    }




    default: {
      return state;
    }
  }
};
