import React, { useEffect } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleChallan } from "../../store/actions/TripsAction";
import { useLocation, useNavigate } from "react-router-dom";
import SvgImage from "../../Core/Icons";
import { ClipLoader } from "react-spinners";
import { searchDriver, searchDriverHistory } from "../../store/actions/driverAction";
import moment from "moment";
import { motion } from "framer-motion";

function DriverSearchHistoryList(props) {
  const { vrn } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { vehicleChallan } = useSelector((state) => state?.trip);
  console.log("jsrfjr", vehicleChallan);

    useEffect(() => {
      console.log("sjrofojrs");
        dispatch(searchDriverHistory({}, (resp) => {}));
    }, []);

const {driverSearchHistoryList} = useSelector(state => state?.driver)
console.log("dojrofj",driverSearchHistoryList)
  // vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data
  const cardVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 10,
      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
    
  };
  // Disposed_data
  return (
    <div className="">
      <Header />
      {/* 
      {driverSearchList?.data == false ? <div>OOPS... SERVER DOWN</div> : !driverSearchList?.data?.response ? (
        <div>LOADING....</div>
      ) : ( */}
      <>
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 40px 0", gap: "10px", padding: "0 7% 0 7%" }}
      >
        <div style={{ textAlign: "left" }} className="fontNB24">
        Your Requests
        </div>

        <div>
          {driverSearchHistoryList?.map((item) => {
                      let dif = moment.duration(
                        moment(new Date()).diff(
                          moment(item?.createdAt, "YYYY-MM-DD HH:mm:ss")
                        )
                      );
                      let posted1, posted2;
            
                      if (dif?.days())
                        posted1 = moment(item?.createdAt).format("DD-MM-YYYY");
                      else
                        posted2 =
                          (dif?.hours() ? dif?.hours() + "h " : "") +
                          (dif?.minutes()
                            ? dif?.seconds()
                              ? parseFloat(dif?.minutes()) + 1 + "m "
                              : dif?.minutes() + "m "
                            : "");
            return(
              <motion.div className="d-flex flex-column" 
              style={{    margin: "15px 0 0 0"}}
              initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div
                variants={cardVariants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}
                  className="grey-box-mini-2 d-flex"
                  style={{
                    justifyContent: "space-between",
                    background: item?.status == 2 ? "#6DA66880" : "#DEDFD733",
                    margin : "0 0 15px 0"
                  }}
                >
                  <div
                    className="d-flex flex-column"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="fontNB20">{item?.from} - {item?.to}</div>
                    <div className="fontNM12">                    {item?.date} | {item?.days ? item?.days + " Days" : ""}{" "}
                      
                    </div>
                    <div className="fontNM12">  Expected Pay : {item?.bidAmount}
                      
                      </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="fontNR10" style={{ textAlign: "end" }}>
                    Posted {posted1 ? "on " + posted1 : posted2 + " ago"}

                    </div>
                    {!item?.status || item?.status == "0"?                     
                    <div className="addCustcenter" style={{ margin: "20px 0 0 0" }} onClick={() => {
                       dispatch(
                        searchDriver({
                          searchId : item?.searchId
                        }, (resp) => {
                          // setLoader(false)
                          if (resp?.status == 200) {
                            navigate(`/driverSearchList/${resp?.data?.data?.search?.searchId}`)
                        }
                        })
                      );
                    }}>
                      <button
                        className="greenButton Medium text3 AddCustStartBtn"
                        style={{ padding: "0 20px 0 20px" }}
                      >
                        <div className="fontNM12" style={{ color: "white" }}>
                          {item?.bidCount}  Received Bids
                        </div> 
                      </button>
                    </div> : item?.status == 1 ? <div className="addCustcenter" style={{ margin: "20px 0 0 0" }} onClick={() => {
                       dispatch(
                        searchDriver({
                          searchId : item?.searchId
                        }, (resp) => {
                          // setLoader(false)
                          if (resp?.status == 200) {
                            navigate(`/driverSearchList/${resp?.data?.data?.search?.searchId}`)
                        }
                        })
                      );
                    }}>
                      <button
                        className="greenButton Medium text3 AddCustStartBtn"
                        style={{ padding: "0 20px 0 20px" }}
                      >
                        <div className="fontNM12" style={{ color: "white" }}>
                          Number shared
                        </div>
                      </button>
                    </div> : <div className="addCustcenter" style={{ margin: "20px 0 0 0" }} onClick={() => navigate(`/PayDriver/${item?.searchId}`,{state : {details : item,searchData : item}})}>
                      <button
                        className="greenButton Medium text3 AddCustStartBtn"
                        style={{ padding: "0 20px 0 20px" }}
                      >
                        <div className="fontNM12" style={{ color: "white" }}>
                        Track Your truck 
                        </div>
                      </button>
                    </div>}
                  </div>
                </motion.div>
      
      
      
              </motion.div>
            )
          })}
        </div>



        <div className="addCustcenter" style={{ margin: "30px 0 0 0" }} onClick={() => navigate("/SearchDrivers")}>
          <button
            className="greenButton Medium text3 AddCustStartBtn"
            style={{ padding: "0 20px 0 20px" }}
          >
            <div className="AddCust">Create New Request</div>
          </button>
        </div>
      </div>

      </>
      {/* )} */}
    </div>
  );
}

export default DriverSearchHistoryList;
