import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../../../Core/Icons";
import DriverHeader from "../../../../SubComponents/DriverHeader";

//toast.configure()
function DriverEarnings(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");
  const dispatch = useDispatch();

  const datepickerRef = useRef();

  const [openBidModal, setOpenBidModal] = useState(false);

  const {driver} = useSelector(state => state?.login)
  console.log("oejroj",driver)
  return (
    <div className="">
      <DriverHeader />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 0 0", gap: "10px", padding: "0 7% 0 7%" }}
      >
        <div style={{ textAlign: "left" }} className="fontNB24">
        Your Earnings!
        </div>


        <div
          className="grey-box-mini d-flex flex-column"
          style={{
            justifyContent: "space-between",
            background:
              "linear-gradient(91.33deg, rgba(0, 0, 0, 0.2) 1.24%, rgba(212, 213, 214, 0.2) 60.37%)",
          }}
        >
          <div
            className="d-flex"
            style={{ justifyContent : "space-between" }}
          >
            <div className="fontNB20">Earnings Till Now</div>
            <div className="fontNB18">₹3,000.00</div>
          </div>
          <div className="d-flex"  style={{ justifyContent : "space-between" }}>
            <div className="fontNS15">Upcoming Payments</div>
            <div className="fontNS14">₹3,000.00</div>
          </div>
        </div>

        <div className="fontNS16 mt-2 mb-4 add-detail-btn">
        Add Bank Details for Payment
        </div>
      </div>

      <div className="earning-box d-flex flex-column">
        <div
            className="d-flex"
            style={{ justifyContent : "space-between" }}
          >
            <div className="fontNS14">Advance for Trip on 29 Sep</div>
            <div className="fontNB16" style={{color : "#09BD7B"}}>₹3,000.00</div>
          </div>
          <div className="d-flex"  style={{ justifyContent : "space-between" }}>
          <div className="fontNS10" style={{color : "#7C7C81"}}>₹3,000.00</div>
          <div className="fontNS14">₹3,000.00</div>
          </div>
        </div>

    </div>
  );
}
export default DriverEarnings;
