import React, { useEffect, useState } from "react";
import SvgImage from "../../Core/Icons";
import { useMediaQuery } from "@mui/material";
import "./index.css"
import { useDispatch } from "react-redux";
import { getSignedUrlApiAction } from "../../store/actions/appActions";

//toast.configure()
function DriverHeader(props) {

    const isMobile = useMediaQuery("(max-width: 768px)");
    let driver = JSON.parse(sessionStorage.getItem("_driver"))
    const [displayName, setDisplayName] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(driver?.image){
            dispatch( 
                getSignedUrlApiAction(
                  { key: driver?.image },
                  (res) => {
                    if (res.status == 200) {
                        setDisplayName(res?.data?.data)
                    }
                  }
                )
              );
        }
      },[])
  return (
    <div
      className="d-flex" style={{width : "100%", position:"relative",padding: "7% 5% 0% 0%",justifyContent : "space-between"}}>
        <div style={{    display: "flex",
    alignItems: "center"}}>
        <SvgImage name = "TerminalLogo" />
        </div>
        <div className="driver-header-img-main">
            {displayName ?         <img
            className="driver-header-img"
                          src={displayName}
                          style={{ width: "100%" }}
                        /> : <></>}
        </div>
    </div>
  );
}
export default DriverHeader;
