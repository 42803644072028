import React, { useEffect, useState } from "react";
import Header from "../../../../SubComponents/Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleChallan } from "../../../../store/actions/TripsAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SvgImage from "../../../../Core/Icons";
import { ClipLoader } from "react-spinners";
import StarIcon from "@mui/icons-material/Star";
import {
  createOrder,
  driverSendInterest,
  searchDriver,
  tripPaymentHistory,
} from "../../../../store/actions/driverAction";
import { motion } from "framer-motion";

function PayDriver(props) {
  const { vrn } = props;
  const { state } = useLocation();

  if (!state) {
    navigate("/driverSearchHistory");
  }
  let { details, searchData } = state;
  const dispatch = useDispatch();
  const [driverIds, setDriverIds] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();

  //   const { driverSearchList } = useSelector((state) => state?.driver);
  //   console.log("jsrfjr", driverSearchList);
  console.log("sfojker", details, searchData);
  useEffect(() => {
    if (!details) {
      console.log("sjrofojrs");
      navigate(`/driverSearchList/${id}`);
    }
  }, []);
  const [amount, setAmount] = useState(0);
  const [remarks, setRemarks] = useState("");

  const {tripPaymentHistoryList} = useSelector((state) => state?.driver)

  const handleCheckout = (amt) => {
    dispatch(
      createOrder(
        {
          searchId: searchData?.searchId,
          driverId: details?.driverId,
          paymentType: details?.status > 0 ? "midTrip" : "Advance",
          amount: amt,
        },
        (resp) => {
          console.log("merogoret", resp);
          // {
          //     "orderId": 2,
          //     "userId": 1,
          //     "searchId": 68,
          //     "driverId": 6,
          //     "phone": "9816475869",
          //     "name": "Tez",
          //     "paymentType": "advance",
          //     "orderType": 1,
          //     "amount": 194.6,
          //     "orderUuid": "bd9188f1-3944-4c66-b548-bcf33e014bec",
          //     "externalTxnIdC": "order_1928242meYm0OVDQX5UH57iVj67Wk95qa",
          //     "remarks": "advance Payment for trip Pune - Mysore  driver naren ",
          //     "sessionIdC": "session_elmba2GdvT1OMfiiUV9WZ3pwOzOOMdLyDA18yGMMJt02cpJDzeLhL1CK4HIlfq6XifHg4hx01mW2mjA3BnSIUCg7n6g74stz9XiKoZy3DsT7"
          // }
          if (resp?.status == 200 && resp?.data?.data?.orderId) {
            let upiId = `pay?pa=logipe@dbs&pn=terminal&tr=terminal.${resp?.data?.data?.orderId}&tn=terminal.${resp?.data?.data?.orderId}&am=${resp?.data?.data?.amount}`;
            navigate("/PaymentMethods", {
              state: {
                upiId,
                paymentData: resp?.data?.data,
                orderId: resp?.data?.data?.orderId,
                searchData: searchData,
              },
            });
          }
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      tripPaymentHistory(
        {
          driverId: details?.driverId,
          searchId: searchData?.searchId,
        },
        (resp) => {
          console.log("yuuuu", resp);
        }
      )
    );
  }, []);

  const cardVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 10,
      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
    
  };

  return (
    <div className="">
      <Header />
      {/* 
      {driverSearchList?.data == false ? <div>OOPS... SERVER DOWN</div> : !driverSearchList?.data?.response ? (
        <div>LOADING....</div>
      ) : ( */}
      <>
        {" "}
        <div
          className="d-flex flex-column"
          style={{ margin: "10px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">Pay & Book Your Driver</div>
        </div>
        <div className="fontNS16" style={{ color: "rgba(108, 108, 108, 1)" }}>
          Booking Details
        </div>
        <div
          className="d-flex flex-column"
          style={{ gap: "0px", padding: "10px 0 20px 0" }}
        >
          <div
            className="d-flex flex-column"
            style={{ marginTop: "10px", gap: "10px" }}
          >
            <div className="d-flex flex-column">
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  gap: "20px",
                  // border: "1px solid rgba(108, 108, 108, 0.24)",
                  borderBottom: "none",
                }}
              >
                <div
                  style={{ width: "30%", padding: "15px" }}
                  className="d-flex flex-column"
                >
                  <div>
                    {details?.image ? (
                      <img
                        src={`https://storage.googleapis.com/logipe-assets/${details?.image}`}
                        style={{ width: "80px" }}
                      />
                    ) : (
                      <img
                        src={`/assets/images/image.png`}
                        style={{ width: "80px" }}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{ width: "70%", gap: "15px", padding: "10px" }}
                  className="d-flex flex-column"
                >
                  <div
                    className="d-flex"
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ textAlign: "left" }}
                    >
                      <div className="fontNS16" style={{ color: "#000000" }}>
                        {details?.name || ""}
                      </div>
                      <div className="fontNM12">
                        Booking Amount : ₹{" "}
                        {details?.driverBid?.toFixed(2) || ""}
                      </div>
                      <div className="fontNM12">
                        Trip Date : {searchData?.date || ""}
                      </div>
                      <div className="fontNM12">
                        Trip Details : {searchData?.from || ""} to{" "}
                        {searchData?.to || ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {details?.status > 0 ? (
          <div
            className="d-flex flex-column"
            style={{ padding: "15px", gap: "10px" }}
          >
            <div style={{ textAlign: "left" }} className="fontNB18">
              Billing Details
            </div>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="fontNS14">Amount</div>
              <input
                type="number"
                className="inputBoxAddCust"
                // style={{ width: "100%" }}
                inputMode="numeric"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target?.value);
                }}
                placeholder="₹"
              />
            </div>

            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="fontNS14">Remarks</div>
              <input
                type="text"
                className="inputBoxAddCust"
                // style={{ width: "100%" }}
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target?.value);
                }}
              />
            </div>

            <div style={{ border: "1px solid rgba(0, 0, 0, 1)" }}></div>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="fontNB20">Total</div>
              <div className="fontNB20">₹ {parseFloat(amount || 0)}</div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex flex-column"
            style={{ padding: "15px", gap: "10px", marginBottom: "100px" }}
          >
            <div style={{ textAlign: "left" }} className="fontNB18">
              Billing Details
            </div>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="fontNS14">Booking Token Amount (10%)</div>
              <div className="fontNS12">₹{details?.advance?.toFixed(2)}</div>
            </div>

            {searchData?.convenienceFee == "0" ? (
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="fontNS14">Convenience Fee</div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div
                    className="fontNS12"
                    style={{
                      textDecoration: "line-through",
                      textDecorationColor: "green",
                    }}
                  >
                    ₹ 50
                  </div>
                  <div className="fontNS12">₹ {searchData?.convenienceFee}</div>
                </div>
              </div>
            ) : (
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="fontNS14">Convenience Fee</div>
                <div className="fontNS12">₹ {searchData?.convenienceFee}</div>
              </div>
            )}

            {searchData?.convenienceFee != "0" && (
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="fontNS14">Taxes</div>
                <div className="fontNS12">
                  ₹ {parseFloat(searchData?.convenienceFee) * 0.18}
                </div>
              </div>
            )}

            <div style={{ border: "1px solid rgba(0, 0, 0, 1)" }}></div>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="fontNB20">Total</div>
              <div className="fontNB20">
                ₹{" "}
                {(
                  details?.advance +
                  parseFloat(searchData?.convenienceFee) +
                  parseFloat(searchData?.convenienceFee) * 0.18
                )?.toFixed(2)}
              </div>
            </div>
          </div>
        )}
        <div
          className="mt-4"
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            if (details?.status > 0) {
              if (amount < 1) alert("amount should be greater than 0");
              else handleCheckout(amount);
            } else
              handleCheckout(
                (
                  details?.advance +
                  parseFloat(searchData?.convenienceFee) +
                  parseFloat(searchData?.convenienceFee) * 0.18
                )?.toFixed(2)
              );
          }}
        >
          <div className="request-btn-1">Pay & Confirm Booking</div>
        </div>





        <div style={{padding : "10px"}}>
          {tripPaymentHistoryList?.map((item) => {
            return (
              <motion.div
                className="d-flex flex-column"
                style={{ margin: "15px 0 0 0" }}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div
                  variants={cardVariants}
                  className="grey-box-mini-2 d-flex"
                  style={{
                    justifyContent: "space-between",
                    background: item?.status == 2 ? "#6DA66880" : "#DEDFD733",
                    margin: "0 0 15px 0",
                  }}
                >
                  <div className="d-flex" style={{ alignItems: "flex-start" }}>
                    <div className="fontNB20" style={{ width: "70%" }}>
                      {item?.description}
                    </div>
                    <div className="fontNB20" style={{ width: "30%" }}>
                      {" "}
                      ₹ {item?.amount}
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            );
          })}
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export default PayDriver;
