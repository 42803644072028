import React, { useEffect, useState } from "react";
import MainApp from "./MainApp";
import { BrowserRouter } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import initStore from "./store/index";
import './App.css';

function App() {
  const store = initStore;

  return (
    <div className="App ">
      <Provider store={store}>
        <BrowserRouter>
          <MainApp 
              />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
