import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import QRCode from 'react-qr-code';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SocketContext } from '../../../../Utils/socket';
import { io } from 'socket.io-client';

const PaymentMethods = () => {
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [cardDetails, setCardDetails] = useState({ cardNumber: '', expiry: '', cvv: '' });
  const [upiId, setUpiId] = useState('');

  const {state} = useLocation()
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCardDetailsChange = (event) => {
    setCardDetails({
      ...cardDetails,
      [event.target.name]: event.target.value
    });
  };

  console.log("skrokoer",state?.upiId)

  const handleUpiIdChange = (event) => {
    setUpiId(event.target.value);
  };
  const navigate = useNavigate();


  useEffect(() => {
    if (!state?.paymentData) {
      console.log("sjrofojrs");
      navigate(`/driverLogin`);
    }
  }, []);

  const handlePaymentSubmit = (name = null) => {
    if (paymentMethod === 'card') {
      console.log('Card Details:', cardDetails);
    } else {
      if(name == "Google Pay") window.location.href = `tez://upi/${state?.upiId}`;
      if(name == "PhonePe") window.location.href = `phonepe://upi/${state?.upiId}`;
      if(name == "Paytm") window.location.href = `Paytm://upi/${state?.upiId}`;
    }
  };

  const [success,setSuccess] = useState(false);
  

  let socket
  useEffect(() => {
    let token2 = sessionStorage.getItem("_token");
    token2 = JSON.parse(token2);
    if(token2){
      socket = io("https://gke-socket.logipe.com/", {
            query: { token: token2 },
            transports: ["websocket"],
        }); 
        
        socket.on("connect", (data) => {
          console.log("connect socket");
        });

        socket.on(`PayIn/${state?.orderId}`,(data) => {
          if(data?.status == 2){
            setSuccess(true)
          }
        })
    }
  },[socket]) 

  const upiApps = [
    {
      name: 'Google Pay',
      icon: '/assets/images/google-pay.png',
      url: 'upi://pay?pa=googlepay@upi'
    },
    {
      name: 'PhonePe',
      icon: '/assets/images/phonepe-icon.png',
      url: 'upi://pay?pa=phonepe@upi'
    },
    {
      name: 'Paytm',
      icon: '/assets/images/paytm.png',
      url: 'upi://pay?pa=paytm@upi'
    }
  ];
  return (
    <Container>
      
      <FormControl component="fieldset">
        <FormLabel component="legend">Select Payment Method</FormLabel>
        <RadioGroup
          aria-label="payment-method"
          name="paymentMethod"
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
        >
          <FormControlLabel value="card" control={<Radio />} label="Credit/Debit Card" />
          <FormControlLabel value="upi" control={<Radio />} label="UPI" />
        </RadioGroup>
      </FormControl>

      {paymentMethod === 'card' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <TextField
              label="Card Number"
              name="cardNumber"
              value={cardDetails.cardNumber}
              onChange={handleCardDetailsChange}
              fullWidth
              required
            /> */}
          </Grid>
        </Grid>
      )}

      {paymentMethod === 'upi' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <QRCode value = {`upi://${state?.upiId}`} />
          </Grid>
          <>
          <Typography variant="h6" gutterBottom style={{ margin : "20px 0 20px 20px" }}>Select UPI App</Typography>
          <Grid container spacing={2} style={{ margin : "0px 20px 0px 20px" }}>
            {upiApps.map((app) => (
              <Grid item xs={4} key={app.name}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => handlePaymentSubmit(app.name)}
                  style={{ textTransform: 'none', padding: '10px' }}
                >
                  <img
                    src={app.icon}
                    alt={app.name}
                    style={{ width: '40px', marginRight: '10px' }}
                  />
                  {/* {app.name} */}
                </Button>
              </Grid>
            ))}
          </Grid>
        </>
        </Grid>
      )}

      {paymentMethod != 'card'  ? 
        <></> 
        :  <Button variant="contained" color="primary" onClick={handlePaymentSubmit} sx={{ mt: 3 }}>
        Proceed to Pay
      </Button>} 

      {success && <Navigate to={`/Success/${state?.searchData?.searchId}`} />}
      
    </Container>
  );
};

export default PaymentMethods;