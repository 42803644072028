import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { storeDataToAsyncStorage } from "../../../../Utils/functions";
import { ClipLoader } from "react-spinners";
import SvgImage from "../../../../Core/Icons";
import { DatePickerField } from "../../../../Core/DatePicker/input";
import { Formik, Field, ErrorMessage } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { getCities, getSignedUrlApiAction, storeMedia } from "../../../../store/actions/appActions";
import { driverUpdateDetails } from "../../../../store/actions/driverAction";
import { Autocomplete, TextField } from "@mui/material";
//toast.configure()
function DriverUpdateDetails(props) {
  const { state } = useLocation();
  console.log("jojoejw", state);
  const navigate = useNavigate();
  const [isSendOtp, isSetSendOtp] = useState(0);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState("");
  const [status, setStatus] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState("");

  const datepickerRef = useRef();
  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setOpen(!datepickerElement.open);
  };

  let driver = JSON.parse(sessionStorage.getItem("_driver"))
  const [address, setAddress] = useState(-1);

  useEffect(() => {
    console.log("ajiejiaekmrkew");
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("msoejfojwe", position);
        dispatch(
          getCities({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          })
        );
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);
  const {cities} = useSelector(state => state?.app)
  const [open1, setOpen1] = useState("");


  useEffect(() => {
    if(address != -1){
      let timeout = setTimeout(() => {
        dispatch(getCities({ search: address }));
      }, 750);
      return () => {
        clearTimeout(timeout);
      };  
    }
  }, [address]);

  useEffect(() => {
    if(driver?.image){
        dispatch(
            getSignedUrlApiAction(
              { key: driver?.image },
              (res) => {
                if (res.status == 200) {
                    setDisplayName(res?.data?.data)
                }
              }
            )
          );
    }
  },[])
  return (
    <div className="">
      <Header />
      <div
        className="d-flex flex-column"
        style={{ margin: "50px 0 0 0", gap: "10px" }}
      >
        <div className="FontNB24">{"Update Your Details"}</div>
        <div className="FontNS20">Get High Paying Trips</div>

        <Formik
          enableReinitialize
          initialValues={{
            name: driver?.name || "",
            dob: driver?.dob || "",
            locations: driver?.locations?.length > 0 ?driver?.locations :  [],
            dlNo: driver?.dlNo || "",
            aadhar: driver?.aadhar || "",
            images: "",
          }}
          onSubmit={(values, actions) => {
            if (isSendOtp == "0") isSetSendOtp("1");
            else {
              setLoader(true)
                if(values?.locations){
                    let loc = values?.locations?.map((txt) => {
                      console.log("joijrdso",txt)
                        return {
                            latitude : txt?.lat,
                            longitude : txt?.long,
                            city : txt?.cityName,
                            state :  txt?.State?.stateName,
                            cityName : txt?.cityName,
                        }
                    })
                    values.locations = loc;
                }
                console.log("emrkjgirot",values?.locations)
              dispatch(
                driverUpdateDetails(
                  {
                    ...values,
                    userType: 1,
                  },
                  (resp) => {
                    setLoader(false)
                    if (resp?.status == 200) {
                      storeDataToAsyncStorage(
                        "_driver",
                        resp?.data?.data?.driver
                      );
                    }
                  }
                )
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   marginTop: "30px",
                // }}
                style={
                  {
                    // padding: "20px 0 0 40px",
                  }
                }
              >
                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    margin: "10px 0 0 0 ",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>Name</div>
                    <input
                      type="text"
                      className="inputBoxAddCust"
                      style={{ width: "100%" }}
                      value={values?.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value, true);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    gap: "12px",
                    width: "100%",
                    margin: "10px 0 0 0",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>Date of Birth</div>
                    <div style={{ position: "relative", width: "100%" }}>
                      <DatePickerField
                        classname="inputBoxCust1"
                        name="dob"
                        header="dob"
                        value={values.dob}
                        onChange={(v) => setFieldValue("dob", v, true)}
                        maxDate={new Date()}
                      />
                      <label
                        className="date-logo"
                        onClick={() => {
                          handleClickDatepickerIcon();
                        }}
                      >
                        <SvgImage
                          name="CalenderLogo"
                          width="32"
                          height="32"
                          // classname="date-logo"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    margin: "10px 0 0 0 ",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>Locations</div>
                    <Autocomplete
                      // freeSolo
                      options={cities}
                      loading={loader}
                      multiple
                      isOptionEqualToValue={(option, value) => {
                        return option?.cityName == value?.cityName;
                      }}
                      getOptionLabel={(item) => {
                        console.log("Tripmkmeromore", item);
                        let reqValue = "";
                        if (item) {
                          reqValue = item?.cityName;
                        }
                        return reqValue;
                      }}
                      onOpen={() => {
                        console.log("moo3");
                        setOpen1(!open1);
                      }}
                      value={values?.locations || ""}
                      onInputChange={(event, newValue) => {
                        console.log("Tripjiejroj", event, newValue);

                        setAddress(newValue);

                        if (!newValue) {
                          // setUserId("");
                          setFieldValue("locations", null, true);
                        }
                      }}
                      onChange={(event, newValue) => {
                        setFieldValue("locations", newValue, true);
                      }}
                      sx={{
                        // width: '300px',          // Custom width
                        // backgroundColor: '#f0f0f0', // Background color for the input
                        borderRadius: "8px", // Rounded corners
                        "& .MuiInputBase-root": {
                          padding: "0px",
                          margin: 0, // Custom padding inside the input
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "blue", // Custom color for dropdown arrow
                        },
                        "& .MuiAutocomplete-listbox": {
                          backgroundColor: "#fff", // Background color for dropdown options
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          {/* <input
                            type="text"
                            {...params.inputProps}
                            style={{ width: "100%" }}
                            placeholder="From"
                            // value={value}
                            // onChange={(e) => setFieldValue("from", e.target.value, true)}
                            className="inputBoxAddCust"
                          /> */}

<TextField
            {...params}
            variant="standard"
            // label="Multiple values"
            placeholder="Locations"
          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </FormGroup>

              <FormGroup
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   marginTop: "30px",
                // }}
                style={
                  {
                    // padding: "20px 0 0 40px",
                  }
                }
              >
                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    margin: "10px 0 0 0 ",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>
                      Driving License Number
                    </div>
                    <input
                      type="text"
                      className="inputBoxAddCust"
                      style={{ width: "100%" }}
                      value={values?.dlNo}
                      onChange={(e) => {
                        setFieldValue("dlNo", e.target.value, true);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{
                    padding: "0 15px 0 15px",
                    margin: "10px 0 0 0 ",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>Aadhaar No</div>
                    <input
                      type="text"
                      className="inputBoxAddCust"
                      style={{ width: "100%" }}
                      value={values?.aadhar}
                      onChange={(e) => {
                        setFieldValue("aadhar", e.target.value, true);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    padding: "0 15px 0 15px",
                    margin: "10px 0 0 0 ",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column" style={{ width: "100%" }}>
                    <div style={{ textAlign: "left" }}>Upload your Photo</div>

                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="d-flex" style={{ marginTop: "10px" }}>
                        <Button
                          className="add-attach-for-fastag-linked-vehicle-new"
                          type="button"
                        >
                          {imageLoader ? (
                            <div style={{ zIndex: 10 }}>
                              <ClipLoader
                                color={"#485B7C"}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <SvgImage
                                name="AddAttachment"
                                height="25"
                                width="25"
                              />
                              <input
                                type="file"
                                // multiple
                                // accept="image/*"
                                // value={values.images}
                                accept="image/*"
                                className="add-lr-attachment"
                                onChange={(e) => {
                                  const img = e?.target.files[0];
                                  console.log("image", img?.name);
                                  // e.preventDefault();

                                  if (img == null) {
                                    //clear image
                                    setFieldValue("images", "", true);
                                    //for now  only one supported
                                  } else {
                                    console.log("upload", {
                                      img: img,
                                      uploadType: "images",
                                      isRes: true,
                                    });
                                    setImageLoader(true);
                                    dispatch(
                                      storeMedia(
                                        {
                                          img: img,
                                          uploadType: "images",
                                          isRes: false,
                                        },
                                        (res) => {
                                          setImageLoader(false);
                                          setStatus(true);

                                          // e.preventDefault();
                                          setDisplayName(res?.signedUrl);
                                          if (res?.url) {
                                            console.log("opt", res?.signedUrl);
                                            setFieldValue(
                                              "images",
                                              res?.url,
                                              true
                                            );
                                          }
                                        }
                                      )
                                    );
                                    // setFieldValue("amount", values.amount, true);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </Button>

                        <div
                          className="heading3 Regular"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Upload
                        </div>
                      </div>

                      <div>
                        {displayName ? <img src = {displayName} style={{width : "50px"}}/> : ""}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="greenButton AddCust Medium text3 AddCustStartBtn addCustcenter"
                  style={{ padding: "0 20px 0 20px", margin: "30px 0 0 0" }}
                >
                    {loader ? (
                      <ClipLoader
                        color={"#2E7C7B"}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "Submit"
                    )}
                </button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>

      {/* <ToastContainer position="bottom-right" /> */}
    </div>
  );
}
export default DriverUpdateDetails;
